.subscribe-block {
    position: relative;
    padding: 48px 28px 50px;
    margin: 0 auto 52px;
    background: #fff;
    box-shadow: 0 0 20px rgba(5, 51, 103, 0.06);
    max-width: 480px;
    .blog-single-page & {
        margin-bottom: 37px;
    }
    .subscribe-title {
        margin: 0 0 16px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        font-family: $second-ff;
        text-transform: uppercase;
        text-align: center;
    }
    .form-group {
        margin: 0 auto 44px;
        @include media(">tablet") {
            margin: 0 0 52px;
            width: 100%;
            padding: 0 15px 0 0;

        }
        input {
            width: 100%;
            margin: 0;
        }
    }
    .submit-wrap {
        font-size: 0;
        text-align: center;
        .custom-link {
            display: inline-block;
            line-height: 24px;
        }
    }
    input {
        display: block;
        width: 100%;
    }
}