@media print {
    @page {
        margin: 1cm;
    }
    .header,
    .footer,
    .socials-colors,
    .return-row,
    img,
    .post-main-img,
    .sidebar{
        display: none;
    }
    *{
        color: $primary-color;
        font-family: Arial, sans-serif;
        background: grey;
    }
    .main.blog-single-page{
        padding-top: 0;
    }
    .post-details-row .socials-colors{
        display: none;
    }
    .blog-single-head{
        height:auto !important;
        min-height: 0 !important;
    }
    .info-block{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .wrapper{
        height:auto;
    }
}