.filters-wrap {
    .other-link-wrap {
        position: relative;
        text-align: center;
        padding: 25px 0;
        box-shadow: 0 0 38px rgba(0, 0, 0, 0.05);
        background: #fff;
    }
}

.filters-list {

    .item {
        position: relative;
        text-align: center;
        padding: 0 35px 0 38px;
        box-shadow: 0 0 38px rgba(0, 0, 0, 0.05);
        background: #fff;
        &:first-child {
            z-index: 10;
        }
        &:nth-child(2) {
            z-index: 9;
        }
        &:nth-child(3) {
            z-index: 8;
        }
        &:nth-child(4) {
            z-index: 7;
        }
        &:nth-child(5) {
            z-index: 6;
        }
        &:before {
            content: '';
            position: absolute;
            bottom: -28px;
            left: 0;
            right: 0;
            height: 27px;
            background: url(../images/shadow-2.png) 50% 0 no-repeat;
        }
        &.active {
            .toggle-block{
                @include transition(opacity);
                margin: -17px 0 0;
                max-height:200px;
                @include show;
            }
            .icon .st0 {
                fill: $danger-color;
            }
        }
        .no-touch  &:not(.active):hover .control svg{
            transform: scale(1.12);
        }
    }
    .field-wrap {
        position: relative;
        margin: 0 0 37px;
        text-align: left;
        .inline-block {
            margin-bottom: 0;
        }
        .floating-label {
            top: 9px;
        }
        .active .floating-label {
            top: 9px;
        }
        input {
            display: block;
            width: 100%;
            margin-bottom: 0;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
    .control {
        position: relative;
        padding: 32px 0 27px;
        cursor: pointer;
    }
    .name {
        position: relative;
        margin: 0 0 34px;
        color: $light-blue;
        font-family: $second-ff;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    .icon {
        position: relative;
        margin: 0 auto;
        pointer-events: none;
        &.home {
            width: 23px;
            height: 20px;
        }
        &.auto {
            width: 27px;
            height: 19px;
        }
        &.motorcycle {
            width: 34px;
            height: 23px;
        }
        &.truck {
            width: 39px;
            height: 22px;
        }
        &.umbrella {
            width: 33px;
            height: 24px;
        }
        svg {
            width: 100%;
            height: 100%;
            @include transition(transform);
        }

    }
    .fields-block {
        padding: 0 0 32px;
    }
    .toggle-block {
        position: relative;
        overflow: hidden;
        max-height:0;
        @include hide;
    }
}