.popular-posts-wrap {
    position: relative;
    padding-bottom: 50px;
    .popular-post {
        padding-top: 24px;
        padding-bottom: 25px;
        border-bottom: 1px solid $gray-3;
    }
    .title {
        margin-bottom: 0;
        font-family: $second-ff;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        padding-bottom: 20px;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-bottom: 1px solid $gray-3;
        .blog-single-page & {
            border: 0;
        }
    }
    .post-date {
        margin: 0 0 14px;
        text-transform: uppercase;
        font-family: $second-ff;
        font-weight: 600;
        font-size: 12px;
    }
    .post-title {
        font-size: 20px;
        line-height: 30px;
        a {
            @include transition(color);
            @include hover {
                color: $red-1;
            }
        }
    }
}