.privacy-wrap {
    position: relative;
    margin: 0 auto;
    max-width:770px;
    @include media(">tablet") {
        h1{
            text-align: center;
            margin-bottom: 50px;
        }

    }
}
.privacy-text{
    font-size: 14px;
    font-family: $third-ff;
    @include media(">tablet") {
        column-count: 2;
        column-gap: 26px;
        text-align: justify;
        h2{
            text-align: center;
            font-size: 22px;
            line-height:28px;
            margin-bottom: 14px;
        }
    }
}