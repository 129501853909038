.modal {
    box-shadow: none;
    min-height: 100vh;
    @include media('>=tablet') {
        min-height: 1px;
    }
    .close-modal {
        padding: 15px;
        top: 0;
        right: 0;
        position: absolute;
        line-height: 1;
        z-index: 1;
        @include media('>=tablet') {
            padding: 20px 15px;
        }
    }
    &.hasShadow::after {
        display: none;
    }
}

.modal-content {
    padding: 80px 15px;
    position: relative;
    @include media('>=tablet') {
        padding: 65px 20px;
    }
}

// login
#login, .broker-login .content {
    background-color: $white;
    @include media('>=tablet') {
        width: 629px;
    }
    .login-modal {
        text-align: center;
        @include media('>=tablet') {
            max-width: 381px;
            margin: auto;
        }
        .title {
            color: $primary-color;
            font-size: 30px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: -0.75px;
            margin-bottom: 36px;
            //@include media('>=tablet') {
            //  margin-bottom: 20px;
            //}
        }
        .form-group {
            margin-bottom: 0;
        }
        .form-control {
            width: 100%;
            margin-bottom: 9px;
        }
        .inline-field {
            margin-bottom: 30px;
            text-align: right;
            //@include media('>=tablet') {
            //  margin-bottom: 7px;
            //}
            .custom-link {
                @include media('<tablet') {
                    color: rgba($primary-color, .57);
                    font-size: 14px;
                    text-transform: none;
                    font-family: "HelveticaNeue", sans-serif;
                    letter-spacing: 0;
                    &:after {
                        display: none;
                    }
                    &:before {
                        height: 1px;
                        width: 100%;
                        background-color: rgba($primary-color, .57);
                        transition: none;
                        transform: none;
                        bottom: -2px;
                    }
                }
            }
        }
        .buttons-group {
            margin-top: 45px;
            @include media('>=tablet') {
                margin-top: 33px;
            }
            .button {
                margin-bottom: 20px;
                @include media('>=tablet') {
                    margin-bottom: 15px;
                }
            }
        }
    }
}

//download

.download-modal-wrap {
    @include media('>=tablet') {
        width: 441px;
    }

    .download-modal {
        text-align: center;
        @include media('>=tablet') {
            max-width: 381px;
        }
    }
}

.policy-modal-wrap {
    @include media('>=tablet') {
        width: 880px;
    }
    .modal-content{
         @include media(">tablet") {
             padding-top: 58px;
             padding-bottom: 69px;
         }
    }
    .policy-modal {
        @include media('>=tablet') {
            max-width: 720px;
            padding: 0 26px;
        }
        .title {
            margin-bottom: 30px;
            text-align: center;
            font-size: 36px;
            line-height:46px;
        }
        p{
            margin-bottom: 20px;
            & + ul{
                margin-top: -6px;
            }
        }
        li{
            line-height: 26px;
            margin:0 0 10px;
        }
        .form-group{
            margin-bottom: 70px;
        }
    }
    .buttons-group {
        text-align: center;
        margin-bottom: 23px;
    }
    .link-wrap {
        display: flex;
        justify-content: center;
        @include media(">tablet") {

         }
        .custom-link {
            margin: 0 15px;
            white-space: nowrap;
                &:before,
                &:after {
                    bottom: 3px;
                }
        }
    }

}

.download-modal,
.policy-modal {
    @include media('>=tablet') {
        margin: auto;
    }
    .title {
        color: $primary-color;
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 20px;
    }
    .form-group {
        margin-bottom: 20px;
    }
    input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        &:checked + label:after {
            @include show;
        }
    }
    label {
        position: relative;
        padding: 5px 0 5px 39px;
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        @include media(">=tablet") {
            padding: 0 0 0 26px;

        }

        &:before,
        &:after {
            position: absolute;
            left: 0;
            top: 0;
            width: 30px;
            height: 30px;
            content: "";
            @include media(">=tablet") {
                width: 14px;
                height: 14px;
                top: 2px;
            }
        }
        &:before {
            background: #fff;
            border: 1px solid $gray-5;
            border-radius: 50%;
        }
        &:after {
            background-image: url(#{$images-path}/icons/check_mark_act.svg);
            background-repeat: no-repeat;
            background-size: contain;
            @include hide;
        }
    }
    .buttons-group {
        margin-top: 45px;
        @include media('>=tablet') {
            margin-top: 33px;
        }
        .button {
            margin-bottom: 0;
        }
    }
}

.document-modal-wrap {
    @include media('>=tablet') {
        width: 978px;
    }
    .modal-content{
        @include media(">tablet") {
            padding-top: 58px;
            padding-bottom: 69px;
        }
    }
    .document-modal {
        @include media('>=tablet') {
            max-width: 978px;
            padding: 0 26px;
        }
        .title {
            margin-bottom: 30px;
            text-align: center;
            font-size: 30px;
            line-height:36px;
        }
        .subtitle{
            margin: 0 0 30px;
            text-transform: uppercase;
            font-family: $second-ff;
            font-size: 20px;
            line-height: 34px;
            font-weight: 600;
            text-align: center;
            letter-spacing: 1px;
        }
        .document-wrap{
            position: relative;
            overflow-x: auto;
            max-width:640px;
            margin: 0 auto;
            padding: 30px 0;
            display: flex;
            justify-content: center;
        }
        .img-wrap{
            position: relative;
            max-width:100%;
            box-shadow: 0 9px 27px rgba(2, 2, 2, 0.1);

        }
    }
}



//become-broker-form
.become-broker-form {
    .form-group {
        margin: 0 -15px 0;
        display: flex;
        align-items: flex-end;
    }
    .inline-block {
        padding: 0 15px;
        margin-bottom: 15px;
        width: 100%;
        .form-control {
            width: 100%;
        }
        >div {
            position: relative;
        }
    }
    .buttons-group {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        .delete {
            font-family: $second-ff;
            font-size: 20px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;

            display: flex;
            align-items: center;

            img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
        }
        .button {
            margin-bottom: 0;
            min-width: 128px;
        }
    }
}

