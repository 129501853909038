.faq-block {
    position: relative;
    padding-top: 126px;
    padding-bottom: 130px;
    .h2 {
        margin-bottom: 20px;
    }
    .accordion {
        margin-bottom: 68px;
        &:last-child {
            margin-bottom: 0;
        }
        h3 {
            margin-bottom: 14px;
            padding-left: 35px;
            font-size: 18px;
            line-height: 24px;
            font-family: $third-ff;
        }
        ul {
            margin-bottom: 14px;
        }
        ul li {
            margin: 0 0 12px;
            line-height: 24px;
            font-size: 14px;
            &:before {
                top: 2px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        p {
            margin-bottom: 10px;
            padding-left: 35px;
            font-size: 14px;
            line-height: 26px;
        }
        .description {
            padding-bottom: 16px;
        }
    }
    .card-link {
        margin: 0 0 0 25px;
    }
}

.faq-wrap {
    position: relative;
    max-width: 772px;
    margin: 0 auto;

    padding-bottom: 60px;
    @include media(">=large") {
        padding-bottom: 132px;

    }

    .faq-block {
        padding: 0;
        margin: 0 0 31px;
        h2 {
            margin-bottom: 35px;
            line-height: 46px;
            @include media(">large") {
                text-align: center;
            }
        }
    }

    .link-wrap {
        &.tac {
            text-align: center;
        }
    }
}