.post-details-row {
    position: relative;
    // justify-content: flex-start;
    //justify-content: center;
    // justify-content: space-between;
    display: flex;
    font-family: $second-ff;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    text-align: left;
    align-items: center;
    margin-bottom: 30px;
    @include media(">tablet") {
        justify-content: center;
        margin-bottom: 0;
    }

    & > * {
        position: relative;
        width: 50%;
        padding-right: 40px;
        // &:after {
        //     position: absolute;
        //     z-index: 0;
        //     width: 40px;
        //     right: 0;
        //     top: 0;
        //     bottom: 0;
        //     content: "";
        //     background: #fff;
        // }
        &:before {
            z-index: 2;
            position: absolute;
            right: 0;
            top: 50%;
            height: 1px;
            width: 37px;
            transform: rotate(-45deg);
            background: $red-1;
            content: "";
        }
        &:first-child {
            width: 60%;
        }
        &:last-child {
            width: 40%;
            padding: 0;
            &:before,
            &:after {
                display: none;
            }
        }
    }

    .author,
    .time-read {
        position: relative;
        font-family: $second-ff;
        text-transform: uppercase;
        font-size: 12px;

    }
    .author {
        overflow: hidden;
        // padding-right: 40px;
        text-align: center;
        @include media(">tablet") {
            // width: 60%;
        }
    }
    .time-read {
        // padding-left: 4px;
        @include media(">tablet") {
            // width: 40%;
        }
    }
    .popular-img-post & {
        margin: 0 0 30px;
    }

}
