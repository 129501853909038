.fullscreen {
    position: relative;
    height: 448px;

    padding-bottom: 20px;
    @include bg(center, center, cover);
    @include media(">=tablet") {
        padding-bottom: 100px;
        height: calc(100vh - 88px);
        overflow: hidden;
    }
    h1 {
        margin: 0 0 26px;
        max-width: 100%;
        text-align: left;
        color: #fff;
    }
    .hero-banner {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        padding: 0 0 20px;
        @include bg(center, center, cover);
        @include media(">=tablet") {
            padding-bottom: 60px;
        }
        @include media(">desktop") {
            padding-bottom: 100px;
        }
    }
    .container {
        width: 100%;
    }
    .intro-block {
        position: relative;
        display: block;
        h1 {
            // display: none;
            text-align: center;
            @include media(">=tablet") {
                text-align: left;
                // display: block;
            }
        }
        // @include media(">=large") {
        @include media(">=desktop") {
            display: inline-block;
        }
        .slick-list {
            padding: 15px 0 24px 15px
        }
        .slick-slide {
            margin: 0 8px 0 0;
            .item {
                height: 205px;
            }

        }
        .slick-btn {
            display: none;
        }
        .slick-dots {
            bottom: 0;
            height: 9px;
            line-height: 0;
        }
        .slick-dots li button {
            background: rgba(#fff, .6);
        }
        .slick-dots .slick-active button {
            background: #fff;
        }
    }
    .quote-accordion {
        position: relative;
        @include media(">=large") {
            padding-right: 46px;

        }
        .item {
            padding: 28px;
            @include media(">=large") {
                padding: 33px 28px;
            }
            .title {
                margin-bottom: 30px;
            }
            .form-control {
                min-width: 164px;
                margin-right: 16px;
            }
        }
    }
    .scroll-icon {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 0 0 0 30px;
        width: 16px;
        height: 60px;
        display: none;

        @include media(">=large") {
            display: block;
            right: 0;
        }
        @include media(">=xlarge") {
            right: 0;
        }
        .svg-scroll.st0 {
            fill: #fff !important;
        }
        &:before {
            position: absolute;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background: #fff;
            content: "";
            top: 41px;
            left: 50%;
            margin: 0 0 0 -1.5px;
            -webkit-animation: pulse 1s infinite;
            animation: pulse 1s infinite;
        }
    }
}

@-webkit-keyframes pulse {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }

}

.home-section {
    .container {
        position: relative;
        z-index: 5;
    }
    .info-block.theme-blue {
        padding-bottom: 55px;
        padding-top: 44px;
        @include media(">=large") {
            padding-bottom: 116px;
            padding-top: 115px;
        }
        @include media(">desktop") {
            padding-bottom: 214px;
            padding-top: 200px;

        }

        .view-link-wrap {
            text-align: center;
            display: block;
            .view-link {
                margin: 0;
            }
            @include media(">tablet") {
                display: none;
            }
        }
    }
    &.about-section {
        position: relative;
        padding-top: 110px;
        padding-bottom: 0;

        @include media("<=320px") {
            padding-top: 180px;
        }

        @include media(">=large") {
            padding-top: 210px;
            padding-bottom: 200px;
        }
        @include media(">=xlarge") {
            padding-top: 238px;
            padding-bottom: 216px;
        }
        @include media(">desktop") {
            //margin-top: -88px;
        }
    }
}

.about-section {
    position: relative;
    @include media(">=large") {
        height: auto !important;
    }
    .px-block {
        z-index: 1;
        position: relative;
        height: 518px;
        overflow: hidden;
        pointer-events: none;
        @include media(">=large") {
            position: absolute;
            right: 0;
            top: 0;
            width: 50vw;
            bottom: 0;
            height: 100%;
            overflow: visible;
        }
    }
    .px {
        position: absolute;
        img {
            display: block;
            max-width: 100%;
        }
    }
    .px-bird {
        right: -22px;
        bottom: 232px;
        width: 406px;
        height: 211px;
        @include media(">tablet") {
            right: 76px;
        }
        @include media(">=large") {
            width: 424px;
            height: 221px;

        }
        @include media(">=xlarge") {
            width: 586px;
            height: 305px;
            right: 3px;
            bottom: 355px;
        }
        @include media(">desktop") {
            bottom: 385px;
            right: 378px;
        }
    }
    .px-car {
        right: -394px;
        bottom: -64px;
        width: 456px;
        height: 321px;
        @include media(">tablet") {
            right: -294px;
            bottom: -50px;

        }
        @include media(">=large") {
            width: 432px;
            height: 306px;
            right: -266px;
            bottom: -29px;
        }
        @include media(">=xlarge") {
            right: -446px;
            bottom: -74px;
            width: 685px;
            height: 482px;

        }
        @include media(">desktop") {
            right: -127px;
            bottom: -15px;
        }
    }
    .px-man {
        bottom: -35px;
        right: 8px;
        width: 426px;
        height: 541px;
        @include media(">tablet") {
            right: 108px;
        }
        @include media(">large") {
            width: 446px;
            height: 566px;
            right: 108px;
        }
        @include media(">=xlarge") {
            width: 599px;
            height: 760px;
            right: 158px;
        }
        @include media(">desktop") {
            right: 484px;
        }
    }

}

.home-about-block {
    position: relative;
    text-align: center;
    padding: 0 0 10px;
    @include media(">=large") {
        max-width: 434px;
        text-align: justify;
        padding: 0 66px 0 0;
        margin: 0 0 0 70px;
    }
    @include media(">desktop") {
        margin: 0;
    }
    &:before {
        @include media('>=tablet') {
            right: 0;
        }
        @include media('>=large') {
            width: 177px;
            height: 180px;
            top: -52px;
        }
    }
    .title {
        position: relative;
        font-size: 40px;
        line-height: 44px;
        max-width: 256px;
        letter-spacing: -1.24px;
        display: inline-block;
        &:before {
            z-index: 1;
            position: absolute;
            display: block;
            width: 102px;
            height: 104px;
            right: 4px;
            top: -58px;
            content: '';
            background: url(#{$images-path}/icons/mark.svg) 0 0 no-repeat;
            @include media('>=large') {
                width: 197px;
                height: 200px;
                top: -74px;
                right: -116px;
            }
        }
        @include media(">=large") {
            font-size: 46px;
            line-height: 52px;
            max-width: 334px;
            margin: 0 0 32px;
        }
    }
    .summary {
        @include media(">=large") {
            font-size: 16px;
        }
    }
}

.posts-slider-head {
    position: relative;
    margin: 0;

    @include media(">tablet") {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    @include media(">=large") {
        margin: 0 0 15px;
    }
    @include media(">desktop") {
        margin: 0 0 30px;
    }
    .title {
        margin: 0 0 10px;
        text-align: center;
        @include media(">tablet") {
            margin: 0;
            text-align: left;
            font-size: 46px;
            line-height: 52px;
        }

    }

    .view-link {
        margin: 6px 0;

        font-size: 16px;
        letter-spacing: 0.5px;
        display: none;
        @include media(">tablet") {
            display: block;
        }
    }
}

.slider-post-wrap {
    position: relative;
    @include media(">=tablet") {
        overflow: hidden;
    }
}

.slider-posts {
    .slick-list {
        padding: 15px 0 24px 15px;
        @include media(">=tablet") {
            padding-left: 0;
        }
    }
    .slick-slide {
        margin: 0 8px 0 0;
        @include media(">=tablet") {
            margin: 0 15px;
        }
        .post {
            margin: 0;
        }
    }
}

