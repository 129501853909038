.footer {
    z-index: 10;
    position: relative;
    padding: 50px 0 36px;
    background: $primary-color;
    color: #fff;
    @include media(">=large") {
        padding-bottom: 40px;
    }
    ul {
        margin-bottom: 0;
        li {
            padding: 0;
            line-height: 1;
            &:before {
                content: none;
            }
        }
    }
    a {
        color: #fff;
    }
    .home & {
        background: #fff;
        color: $primary-color;
        .phone a{
            color: $primary-color;
        }
        .footer-menu a {
            color: $primary-color;
            &:before,
            &:after {
                background-color: $primary-color;
            }
        }
    }
}

.home {
    .footer {
        background: #fff;
        color: $primary-color;
        .phone a{
            color: $primary-color;
        }
        .footer-menu a {
            color: $primary-color;
            &:before,
            &:after {
                background-color: $primary-color;
            }
        }
    }
}

.socials {
    position: relative;
    display: flex;
    .footer & {
        width: 100%;
        justify-content: space-between;
        margin: 0 calc((100% - 220px) / 2);
    }
    @include media(">=large") {
        .footer & {
            width: 148px;
            margin: 0;
        }
    }

}

.socials,
.socials-colors {
    a {
        z-index: 1;
        position: relative;
        display: block;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        color: transparent;
        overflow: hidden;
        li {
            margin-left: 18px;
            &:first-child {
                margin-left: 0;
                @include media(">tablet") {
                    margin-left: 18px;
                }
            }

        }
        @include media(">=large") {
            &:before {
                z-index: -1;
                position: absolute;
                top: 2px;
                bottom: 2px;
                right: 2px;
                left: 2px;
                content: "";
                border-radius: 50%;
                @include transition(background);
            }
        }
        svg path {
            fill: $red-1;
        }
    }
}

.footer-menu-row {
    position: relative;
    display: flex;
    flex-direction: column;
    @include media(">=large") {
        flex-direction: row;
        align-items: flex-start;
    }
    .phone-block {
        display: flex;
        order: 1;
        width: 100%;
        flex-wrap: wrap;
        @include media(">=large") {
            justify-content: flex-end;
            width: 30%;
            padding-top: 53px;
        }
        .phone {
            width: 100%;
            text-align: center;
            font-size: 34px;
            font-family: $second-ff;
            font-weight: 600;
            line-height: 1;
            text-transform: uppercase;
            letter-spacing: 1.72px;
            margin-bottom: 18px;

            @include media(">=large") {
                text-align: right;
                font-weight: 500;
                font-size: 35px;
            }
        }
        .lang-block {
            width: 100%;
            text-align: center;
            @include media(">=large") {
                display: flex;
                justify-content: flex-end;
            }
        }
        @include media('>=large') {
            order: 3
        }
    }

}

.lang-block {
    @include media(">=large") {
        display: flex;
        align-items: center;
    }
    .lang-dt {
        font-family: $third-ff;
        font-size: 14px;
        line-height: 19px;
        white-space: nowrap;
        margin: 0 0 17px;
        @include media(">=large") {
            margin: 0 15px 0 0;
        }
    }
    .lang-dd {
        position: relative;
        margin: 0 0 55px;
        @include media(">=large") {
            margin: 0;
        }
    }
    .lang-active {
        position: relative;
        display: inline-block;
        cursor: pointer;
        color: $red-2;
        font-size: 24px;

        .full {
            @include media(">=large") {
                display: none;
            }
        }
        .short {
            display: none;
            @include media(">=large") {
                display: block;
            }
        }
        .full,
        .short {
            pointer-events: none;
        }
        &:before {
            position: absolute;
            content: "";
            height: 2px;
            width: 100%;
            left: 0;
            bottom: 0;
            background: $red-2;
        }
    }
    .lang-active,
    .lang-list {
        font-family: $second-ff;
        line-height: 1.3;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.73px;
        .item {
            cursor: pointer;
            color: $primary-color;
            font-size: 16px;
            line-height: 32px;
            @include transition(color);
            @include hover {
                color: $red-1;
            }
        }
        .item.active {
            color: $red-2;
        }
    }
    .lang-active {
        //color: $red-2;
    }
    .lang-list {
        z-index: 5;
        position: absolute;
        top: 0;
        left: 50%;
        right: 0;
        transform: translateX(-50%);
        max-width: 400px;
        min-width: 290px;
        padding: 20px 32px 22px;
        background: #fff;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        text-align: left;
        transition: opacity .7s ease;
        box-shadow: 0 3px 43px rgba(0, 0, 0, 0.06);

        @include media(">=large") {
            right: 0;
            width: 190px;
            transform: none;
            left: auto;
        }
        &.open {
            pointer-events: auto;
            opacity: 1;
            visibility: visible;
        }
        // .close-btn {
        //     position: absolute;
        //     top: 14px;
        //     right: 12px;
        //     width: 20px;
        //     height: 20px;
        //     cursor: pointer;
        //     &:before,
        //     &:after {
        //         position: absolute;
        //         height: 2px;
        //         background-color: $light-blue;
        //         width: 18px;
        //         top: 9px;
        //         right: 0;
        //         content: "";
        //     }
        //     &:before {
        //         transform: rotate(45deg);
        //     }
        //     &:after {
        //         transform: rotate(135deg);
        //     }
        // }
        .close-btn {
            position: absolute;
            top: 14px;
            right: 12px;
            width: 20px;
            height: 20px;
            cursor: pointer;
            &:before {
                .touch & {
                    content: "";
                    position: absolute;
                    top: -14px;
                    bottom: -14px;
                    right: -14px;
                    left: -14px;
                }
            }
        }

        .close-btn-icon {
            pointer-events: none;
            display: block;
            height: 20px;
            &:before,
            &:after {
                position: absolute;
                height: 2px;
                background-color: $light-blue;
                width: 18px;
                top: 9px;
                right: 0;
                content: "";
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(135deg);
            }
        }
    }
}

.footer-menu {
    display: flex;
    min-width: 100%;
    order: 2;
    margin: 0 -15px 42px;
    flex-direction: column;
    border-top: 1px solid rgba(#fff, .1);
    border-bottom: 1px solid rgba(#fff, .1);
    padding: 40px 0 30px;
    .home & {
        border-top: 1px solid rgba(#213766, .07);
        border-bottom: 1px solid rgba(#213766, .07);
        @include media(">=large") {
            border: 0
        }
    }
    @include media(">=large") {
        flex-direction: row;
        order: 1;
        min-width: 70%;
        margin: 0;
        padding-top: 0;
        padding-bottom: 15px;
        justify-content: flex-start;
        border: 0;
    }

}

.footer-menu-item {
    position: relative;
    width: 100%;
    padding: 0 15px;
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
    @include media(">=large") {
        width: auto;
        padding: 0 70px 0 0;
        &:last-child {
            padding: 0;
            margin-bottom: 15px;
        }
    }
}

.footer-menu-item .title {
    position: relative;
    font-family: $fourth-ff;
    line-height: 1.3;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.4px;
    margin: 0 0 18px;
    span {
        display: none;
        @include media('>=large') {
            display: inline;
        }
    }
    &:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 6px;
        right: 0;
        top: 50%;
        margin-top: -3px;
        background: url(../images/icons/arrow-white.svg) 0 0 no-repeat;
        .home & {
            background-image: url(../images/icons/arrow.svg);
        }
        @include transition(transform);
        @include media(">=large") {
            display: none;
        }
    }
    &.open:before {
        transform: rotate(180deg);
    }
    @include media(">=large") {
        line-height: 25px;
    }
}

.footer-menu-item .list {
    display: none;
    padding: 0 0 5px;
    a {
        line-height: 36px;
        font-size: 14px;
        font-family: $third-ff;
        @include media(">=large") {
            line-height: 30px;
            color: $gray;
            .home & {
                color: $primary-color;
            }
        }
    }
    @include media(">=large") {
        display: block !important;
        font-size: 14px;
        font-family: $third-ff;
    }
}

.socials-block {
    flex-wrap: wrap;
    margin: 0 0 38px;
    display: flex;
    width: 100%;
    order: 3;
    @include media('>=large') {
        position: absolute;
        right: 0;
        width: auto;
        top: 2px;
        order: 2;
    }
    .title {
        position: relative;
        margin: 0 0 23px;
        width: 100%;
        font-family: $fourth-ff;
        font-size: 16px;
        line-height: 1.3;
        letter-spacing: -0.4px;
        text-align: center;
        @include media(">=large") {
            display: none;
        }
    }
}

.footer .socials-block {
    .instagram {

        z-index: 5;
        @include show;
        svg .st1 {
            fill: $primary-color;
            @include media(">=large") {
                fill: $primary-color;
            }
            .home & {
                fill: $white;
                @include media(">=large") {
                    fill: $white;
                }
            }
        }
        svg .st0 {
            fill: $red-1;
        }

    }
}

.home .footer .socials-block {
    & {
        display: flex;
        align-items: center;
        top: 0;
    }
    & .title {
        display: block;
        @include media(">=large") {
            display: block;
            width:auto;
            margin: 0 26px 0 0;
        }
    }

}

.info-links {
    display: flex;
    justify-content: center;
    margin: 0 0 32px;
    li {
        margin: 0 14px;
        height: 19px;
        @include media(">=large") {
            &:last-child {
                margin-right: 0;
            }
        }
    }
    a {
        font-family: $second-ff;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.2;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        color: #ffffff;
        position: relative;
        .home & {
            color: $primary-color;
        }
        &:before,
        &:after {
            content: '';
            position: absolute;
            bottom: -1px;
            height: 2px;
            width: 51%;
            background-color: $white;
            transform: none;
            transition: transform .4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            .home & {
                background-color: $primary-color;
            }
        }
        &:before {
            left: 0;
            transform-origin: left;
        }
        &:after {
            right: 0;
            transform-origin: right;
        }

        .no-touch & {
            &:hover {
                &:before,
                &:after {
                    transform: scaleX(0);
                }
            }
        }
    }
}

.copyright-row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .copyright {
        //display: flex;
        order: 2;
        width: 100%;
        color: $gray;
        font-family: $third-ff;
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        @include media(">=large") {
            order: 1;
            width: auto;
        }
    }
    .info-links {
        position: relative;
        display: flex;
        order: 1;
        width: 100%;
        margin: 0 0 38px;
        @include media(">=large") {
            order: 2;
            width: auto;
            margin: 0 0 0 auto;
        }
    }
}