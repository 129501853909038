.preloader-wrap{
    z-index: 100;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    transition: opacity .5s ease;
}

#preloader-wrap.loading-complete{
    opacity: 0;
    pointer-events: none;
}


.preloader-line{
    position: absolute;
    top: 0;
    left:auto;
    width: 100%;
    height: 4px;
    background: $red-1;
    transition: transform .3s ease;
    transform: translateX(-100%);
}



@media all and (orientation: portrait) {
    #my-preloader.loading-complete{
        opacity: 0;
        width: 120vh;
        height: 120vh;
        border-radius: 0;
        pointer-events: none;
    }
}