* {
    outline: none;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.noscroll-fixed {
    @include media("<large") {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: fixed;
        min-height: 0 !important;
    }
}

.main {
    z-index: 5;
    position: relative;
    flex-shrink: 0;
    flex-grow: 1;
    width: 100%;
    padding-top: 122px;
    &.blog-page {
        padding-top: 222px;
        @include media(">=large") {
            padding-top: 188px;
        }

        .content-with-sidebar {
            @include media("<large") {
                display: flex;
                flex-direction: column;
                .sidebar {
                    order: 2;
                }
                .main-content {
                    order: 1;
                }
            }
        }
    }
    &.blog-single-page {
        padding-top: 222px;
        @include media(">=large") {
            padding-top: 174px;
        }

        .content-with-sidebar {
            @include media("<large") {
                display: flex;
                flex-direction: column;
                .sidebar {
                    order: 2;
                }
                .main-content {
                    order: 1;
                }
            }
        }
    }
    &.page-type-2 {
        padding-top: 180px;
        padding-bottom: 60px;
    }
    &.contact-page {
        padding-top: 150px;
        @include media(">tablet") {
            padding-top: 190px;
        }
    }

    @include media('>=large') {
        padding-top: 88px;
        .no-touch &.home-page {
            padding-top: 0;
        }
    }
}

.wrapper {
    position: relative;
    overflow-x: hidden;
    width: 100%;
    @include flex(column);
    flex-shrink: 0;
    flex-grow: 1;
    min-height: 100vh;
    height: 100%;
}

.header,
.footer {
    flex-shrink: 0;
}

.container {
    padding: 0 15px;
    @include media('>=xlarge') {
        max-width: 1200px;
        margin: 0 auto;
    }
    @include media('>=desktop') {
        max-width: 1280px;
    }
}

.container-small {
    padding: 0 15px;
    @include media('>=large') {
        max-width: 850px;
        margin: 0 auto;
    }
}

input::-ms-clear {
    display: none;
}

//wpcf7 styles

.wpcf7-not-valid-tip, .wpcf7-response-output {
    display: none !important;
}

input.wpcf7-not-valid {
    border-bottom: 1px solid $danger-color !important;
    color: $danger-color;
    transition: border-color .3s ease, color .3s ease;
    padding-right: 10px;
    position: relative;
    background: {
        image: url(#{$images-path}/icons/alert.svg);
        position: calc(100% + 14px) 19px;
        repeat: no-repeat;
        size: 30px 15px;
    }
;
    @include placeholder() {
        color: $danger-color;
        transition: color .3s ease;
    }
}

.screen-reader-response {
    li:before {
        top: 8px;
    }
}

.inline-block {
    margin-bottom: 35px;
    @include media('>=xlarge') {
        margin-bottom: 43px;
    }
}

button {
    padding: 0;
    vertical-align: top;
    border: none;
    box-shadow: none;
    background: transparent;
    cursor: pointer;
}

//custom links

.custom-link {
    color: $primary-color;
    font-family: $second-ff;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .32px;
    position: relative;
    border: none;
    padding: 0;
    &:before,
    &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        height: 2px;
        width: 51%;
        background-color: $primary-color;
        transform: none;
        transition: transform .4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
    &:before {
        left: 0;
        transform-origin: left;
    }
    &:after {
        right: 0;
        transform-origin: right;
    }
    .no-touch & {
        &:hover {
            &:before,
            &:after {
                transform: scaleX(0);
            }
        }
    }

    &.secondary-link {
        color: $secondary-color;
        background: none;
        &:before,
        &:after {
            background-color: $secondary-color;
        }
    }

    &.small-link {
        letter-spacing: 0.8px;
        font-size: 16px;
    }
}

.indent-link {
    margin-bottom: 20px;
    text-align: center;
}

//aside & content grid

.content-with-sidebar {
    @include media('>=large') {
        display: flex;
        justify-content: space-between;
    }
    .sidebar {
        position: relative;
        z-index: 15;
        @include media('>=large') {
            min-width: 300px;
            width: 31.2%;
            padding-right: 30px;
        }
        @include media('>=xlarge') {
            min-width: 365px;
            padding-right: 95px;
        }
    }
    .main-content {
        @include media('>=large') {
            width: calc(100% - 300px);
        }
        @include media('>=xlarge') {
            padding-right: 69px;
            width: calc(100% - 365px);
        }
    }
}

.info-block {
    position: relative;
    z-index: 1;
    padding: 40px 0 60px;

    &.ver-2 {
        padding-bottom: 200px;
    }

    > img, > p img {
        margin: 0 0 40px;
        max-width: 100%;
        height: auto;
    }
    > ul + img,
    > p + img {
        margin-top: 52px;
        margin-bottom: 46px;
    }
    > ul {
        margin: 0 0 50px;
    }
    h3 + ul {
        margin-top: 30px;
    }
    ul li {
        margin: 0 0 10px;
        line-height: 26px;
        &:before {
            @include media("<large") {
                top: 3px;
            }
        }
    }
    @include media(">=large") {
        padding: 120px 0 128px;
        &.ver-1 {
            padding-top: 120px;
            padding-bottom: 110px;
            h2, .h2 {
                font-size: 36px;
                line-height: 46px;
            }
        }
        &.ver-2 {
            padding-top: 132px;
            padding-bottom: 305px;
            h2, .h2 {
                font-size: 36px;
            }
            p {
                font-size: 16px;
            }
        }
        &.ver-3 {
            p {
                font-size: 16px;
            }
        }
        &.ver-5 {
            .text-columns {
                &, p {
                    font-size: 16px;
                }
            }
        }
    }
    &.justify-mod {
        @include media(">=xlarge") {
            text-align: justify;
            h2, .h2 {
                text-align: left;
            }
        }
    }

    h2, .h2 {
        font-size: 40px;
        font-weight: 500;
        line-height: 1.15;
        margin-bottom: 26px;
        @include media('>=large') {
            font-size: 46px;
            line-height: 50px;
        }
    }
    h3, .h3 {
        font-size: 36px;
        line-height: 46px;
        font-family: $main-ff;
    }
    h2.h1 {
        @include media('<large') {
            font-size: 30px;
            line-height: 36px;
        }
        @include media('>=large') {
            font-size: 46px;
            line-height: 50px;
        }
    }
    h2 {
        font-size: 26px;
        line-height: 32px;
        @include media('>=large') {
            font-size: 54px;
            line-height: 60px;
        }
    }
    p {
        @include media('>=large') {
            font-size: 16px;
            font-weight: 500;
            line-height: 30px;
        }
    }
    .desc {
        p {
            @include media('<large') {
                font-size: 18px;
                line-height: 28px;
            }
            @include media('>=large') {
                font-size: 26px;
                line-height: 38px;
            }
        }
    }
    .desc-min {
        font-size: 18px;
        line-height: 28px;

    }
    & + .hero-inner {
        z-index: 5;
    }
}

.text-columns {
    position: relative;
    @include media(">tablet") {
        column-count: 2;
        column-gap: 30px;
        text-align: justify;

    }
    p {
        font-family: $third-ff;
        font-weight: 400;
        margin-bottom: 0;
    }
}

.theme-blue {
    position: relative;
    color: $white;
    &:before {
        content: '';
        width: 97px;
        height: 100px;
        display: block;
        position: absolute;
        right: 0;
        top: -24px;
        background: {
            image: url(#{$images-path}/icons/mark_about.svg);
            repeat: no-repeat;
        }
    ;
        z-index: 1;
        @include media('>=tablet') {
            right: 135px;
        }
        @include media('>=large') {
            width: 197px;
            height: 200px;
            top: -52px;
        }
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        right: -9999px;
        left: -9999px;
        z-index: -1;
        background-color: $primary-color;
    }
    .text-columns {
        color: $gray-2;
    }
    &.not-icon {
        &:before {
            content: none;
        }
    }
}

.block-icon {
    &:before {
        content: '';
        width: 97px;
        height: 100px;
        display: block;
        position: absolute;
        right: 0;
        top: -24px;
        background: {
            image: url(#{$images-path}/icons/mark_about.svg);
            repeat: no-repeat;
        }
    ;
        z-index: 1;
        @include media('>=tablet') {
            right: 135px;
        }
        @include media('>=large') {
            width: 197px;
            height: 200px;
            top: -52px;
        }
    }
    &.home-about-block {
        &:before {
            top: -72px;
            background-image: url(#{$images-path}/icons/mark.svg);
        }
    }
}

.sign-img {
    position: relative;
    &:before {
        z-index: -1;
        content: '';
        width: 97px;
        height: 100px;
        display: block;
        position: absolute;
        top: -56px;
        right: -20px;
        background: {
            image: url(#{$images-path}/icons/mark.svg);
            repeat: no-repeat;
        }
    ;
        @include media('>=tablet') {
            width: 197px;
            height: 200px;
            top: -108px;
            right: -83px;
        }
    }
}

.hero-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 450px;
    @include bg(center, center, cover);
    padding: 50px 0;
    margin-top: -192px;
    @include media('>=tablet') {
        padding: 100px 0;
        width: 100vw;
        max-width: calc(100vw - 30px);
        //left:-300px;
    }
    @include media('>=large') {
        min-height: 510px;
        left: -300px;
    }
    @include media('>=xlarge') {
        max-width: 1170px;
        min-height: 550px;
        left: -365px;
    }
    @include media('>=desktop') {
        max-width: 1250px;
        //left: -390px;
    }
    @include ie {
        display: table;
        // width: 100%;
        width: 100vw;
        &.in-text {
            display: block;
            width: 100vw;
        }
        .content {
            display: table-cell;
            vertical-align: middle;
        }
    }
}

//slick dots
.slick-dots {
    bottom: -10px;
    li {
        width: auto;
        height: auto;
        &:before {
            display: none;
        }
        button {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: rgba($primary-color, .62);
            padding: 0;
            &:before {
                display: none;
            }
        }
    }
    .slick-active {
        button {
            width: 9px;
            height: 9px;
            background-color: $primary-color;
        }
    }

    //white theme
    &.white-dots {
        li button {
            background-color: rgba($white, .62);
        }
        .slick-active button {
            background-color: $white;
        }
    }
}

.no-touch .anim {
    transform: translateY(30px);
    opacity: 0;

    &.active {
        transition-property: opacity, transform;
        transition: .4s ease;
        transform: translateY(0);
        opacity: 1;
    }
}

.text-center {
    text-align: center;
    * {
        text-align: center;
    }
}

.full-pic {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.headline-block {
    position: relative;
    margin: 0 0 10px;
    @include media(">tablet") {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .button {
        margin-bottom: 0;
    }
    .custom-link {
        align-self: center;
    }
}

.table-wrap {
    position: relative;
    overflow-x: auto;
}

.table,
.table-wrap table {
    width: 100%;
    .tr-th,
    .thead,
    thead {
        background-color: #f4f7fa;

    }
    .th,
    th {
        padding: 26px 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        text-align: left;
    }
    .tr .td,
    tr td {
        font-size: 14px;
    }
    .th, .td,
    th, td {
        border: 0;
        &:first-child {
            padding-left: 30px;
            @include media(">=large") {
                padding-left: 65px;

            }
        }
    }
    .td,
    td {
        padding: 27px 0;
        border-bottom: 1px solid rgba(#193364, .13);
        &:last-child {
            text-align: center;
        }
    }
}

.table {
    .tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .row {
        display: flex;
        justify-content: space-between;
    }
    .td {
        border-bottom: 0;
        line-height: 24px;
    }
    .tbody {
        .tr {
            border-bottom: 1px solid rgba(#193364, .13);

        }
    }
    .hide-block {
        position: relative;
        display: none;
        color: $blue-1;
        background: #f9fafd;
    }
    .inner {
        padding: 25px 176px 64px 44px;
    }
    .tr-wrap.open {
        background: #f9fafd;
    }
}

.claims-table {
    margin: 0 0 52px;
    table {
        min-width: 700px;
    }
    table th,
    table td {
        padding-right: 10px;
    }
    a {
        font-size: 16px;
    }
}

.notifications-table {
    position: relative;
    padding-bottom: 40px;
    .table {
        min-width: 700px;
    }
    .tbody {
        .tr {
            border-bottom: 0;
            background-color: #fff;
            @include transition(background);
            cursor: pointer;
            @include hover {
                background: #f9fafd;

            }
            &.open {
                background: #f9fafd;
            }
        }
        .tr-wrap {
            border-bottom: 1px solid rgba(#193364, .13);
        }

    }
    .th,
    .td {
        &:first-child {
            width: 28%;
        }
        &:nth-child(2) {
            width: 37%;
            font-size: 16px;
        }
        &:nth-child(3) {
            width: 35%;
        }
        &:last-child {
            padding: 27px 60px 27px 0;
            text-align: right;
            .custom-link {
                font-size: 16px;
            }
        }
    }
    .hide-block {
        position: relative;
        display: none;
        color: $blue-1;
        background: #f9fafd;
    }
    .inner {
        padding: 25px 176px 64px 44px;
    }
    .jcf-select-messages.jcf-select {
        margin: 0;
        text-align: left;
        background: none;
        @include media(">large") {
            min-width: 348px;

        }
        .jcf-select-text {
            padding: 0 20px 11px 0;
        }
    }
    .jcf-select-drop {
        background: #fff;
    }
    .date {
        position: relative;
        padding: 0 0 0 38px;
        @include media(">=large") {
            padding: 0;
        }
        .icon {
            position: absolute;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            left: 0;
            top: -1px;
            @include media(">=large") {
                left: -38px;
            }
            path {
                fill: $gray-5;
            }
        }
    }
}

.information-card {
    position: relative;
    margin: 0 0 50px;
    border: 1px solid $gray-3;
    &.policy-mod {
        .row {
            margin-bottom: 24px;
            .dt {
                font-size: 16px;
            }
        }
        .card-col {
            @include media(">=tablet") {
                padding-bottom: 60px;
            }
        }
        .card-col:first-child {
            .dt {
                min-width: 244px;
            }
        }
        .card-col:last-child {
            .dt {
                min-width: 200px;
            }
        }

    }
    @include media(">=tablet") {
        margin: 0 0 90px;
        display: flex;

    }
    .title-row {
        position: relative;
        margin: 0 0 30px;
        @include media(">=large") {
            margin: 0 -62px 36px 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        .custom-link {
            font-size: 20px;
            line-height: 26px;
            display: inline-block;
        }
    }
    .title {
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.65px;
        margin: 0;
    }
    .card-col {
        padding: 30px 15px;
        @include media(">=tablet") {
            width: 50%;
            padding: 58px 100px 68px 0;

        }
        &:first-child {
            border-bottom: 1px solid $gray-3;
            @include media(">=tablet") {
                padding-left: 100px;
                border-bottom: 0;
                border-right: 1px solid $gray-3;

            }
        }
        &:last-child {
            @include media(">=tablet") {
                padding-left: 112px;

            }
            .dt {
                @include media(">tablet") {
                    min-width: 200px;
                }
            }
            .dd {
                max-width: 138px;
            }
        }
    }
    .row {
        position: relative;
        margin: 0 0 44px;
        @include media(">=large") {
            display: flex;

        }
        &:last-child {
            margin: 0;
        }
        &.mod-sub {
            display: block;
        }
        &.address-mod {
            span {
                display: block;
            }
        }

        .note {
            font-size: 16px;
        }
        .dt {
            font-size: 18px;
            line-height: 26px;
            @include media(">tablet") {
                min-width: 198px;
            }
        }
        .dd {
            font-size: 18px;
            line-height: 26px;
            font-family: $second-ff;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
    .sub-row {
        margin: 0 0 8px;
        @include media(">=large") {
            display: flex;

        }
    }
}

.toggle-box {
    position: relative;
    display: flex;

    .toggle-title {
        margin: 0 15px 0 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        font-family: $second-ff;
        .custom-link {
            text-transform: none;
            font-size: 16px;
        }
    }

    .toggle-holder {
        position: relative;
        height: 20px;
        width: 38px;
        input:checked + label {
            border-color: $primary-color;
            &:before {
                background: $primary-color;
                left: 18px;
            }
        }
        label {
            position: relative;
            display: block;
            border: 2px solid $gray-5;
            border-radius: 10px;
            width: 38px;
            height: 20px;
            cursor: pointer;
            &:before {
                position: absolute;
                width: 12px;
                height: 12px;
                background: $gray-5;
                border-radius: 50%;
                left: 2px;
                top: 2px;
                content: "";
                transition-property: background, left;
                transition: .3s ease;
            }
        }
        input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }
    }
}

.tabs-row {
    display: flex;
    .tab {
        line-height: 24px;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: .89px;
        padding: 21px 56px;
        background: $gray-4;
        text-transform: uppercase;
        font-family: $second-ff;
        cursor: pointer;
        &.active {
            background: $primary-color;
            color: #fff;
            pointer-events: none;
            cursor: default;

        }
        .payments-wrap & {
            padding: 21px 34px;
        }
    }
}

.tabs-contents-row {
    .tab-content {
        display: none;
        &.active {
            display: block;
        }
    }
}

.attachment {
    small {
        margin-left: 30px;
        color: rgba($primary-color, .65);
    }
}

.show-for-small {
    display: block;
    @include media(">=tablet") {
        display: none;
    }
}

.pic-hide {
    display: none;
}

.new-phone-svg {
    width: 18px;
    height: 18px;
}

.screen-reader-response ul {
    display: none;
}

.color-red, .color-red * {
    color: $secondary-color;
}

