.blog-wrap {
    position: relative;
    margin-top: -10px;
    padding-bottom: 110px;
    .category-title {
        margin-bottom: 24px;
        text-transform: uppercase;
        font-family: $second-ff;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.4;
        letter-spacing: 1px;
    }
    .blog-summary {
        padding-top: 20px;
        padding-bottom: 26px;

        @include media(">=xlarge") {
            font-size: 18px;
            line-height: 28px;
        }

        .desc {
            @include media(">=xlarge") {
                font-size: 26px;
                line-height: 36px;
            }
        }
    }
}

.posts {
    position: relative;
    padding-bottom: 38px;
    &.slider-posts {
        display: flex;
        margin: 0 auto;
        color: $main-text-color;
        margin: 0 -15px;
        padding-bottom: 0;

        .post {
            display: block;
            margin: 0 15px;
            @include media(">=large") {
                width:calc(25% - 30px);
            }
        }
        .post-picture {
            overflow: hidden;
            height: 183px;
            @include media(">tablet") {
                position: relative;
                height: 226px;
                width: 100%;
                display: block;
            }

            img {
                @include media("<xlarge") {
                    display: none;
                }
            }
        }
        .date {
            margin: 0 0 19px;
            @include media(">tablet") {
                margin-bottom: 28px;
            }
            @include media(">=large") {
                margin-bottom: 16px;
            }
        }
        .day {
            line-height: 26px;
            @include media(">tablet") {
            }
        }
        .month-year {
            line-height: 20px;
            @include media(">tablet") {
                line-height: 22px;
            }
        }

        .post-info {
            width: 100%;
            background: #fff;
            padding:28px 0;
            height: calc(100% - 183px);
            // height: calc(100% - 226px);

             @include media(">tablet") {
                 padding: 22px 30px 25px;
                 height: calc(100% - 226px);
             }
            @include media(">=large") {
                padding: 20px 30px 25px;
            }

            .data-wrap {
                height: 100%;
            }
        }
        .title {
            // margin: 0 0 25px;
            margin: 0 0 53px;
            font-size: 18px;
            line-height: 26px;
            font-family: $fourth-ff;
             @include media(">tablet") {
                //  margin:0 0 17px;
                 margin:0 0 45px;
             }
        }
        .view-link {
            font-size: 16px;
            position: absolute;
            bottom: 28px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.slick-initialized {
    .post-picture {

    }
}

.post {
    position: relative;
    margin-bottom: 27px;
    @include media(">tablet") {
        display: flex;
    }
    &:last-child {
        margin-bottom: 0;
    }
    .post-picture {
        position: relative;
        height: 200px;
        @include media(">tablet") {
            width: 47%;
            height: auto;

        }
        .pic {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
    .post-info {
        position: relative;
        padding: 46px 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        box-shadow: 0 0 20px rgba(5, 51, 103, 0.06);
        @include media(">tablet") {
            width: 53%;
        }
    }
    .pic {
        @include bg(center, center, cover);
        //height: 458px;
    }
    .date {
        margin: 0 0 28px;
    }
    .day {
        font-family: $third-ff;
        font-size: 26px;
        line-height: 30px;
        letter-spacing: -0.65px;
    }
    .month-year {
        font-size: 12px;
        letter-spacing: 0.8px;
        line-height: 22px;
        font-weight: 600;
        font-family: $second-ff;
        text-transform: uppercase;
    }
    .title {
        padding:0 20px;
        margin: 0 0 17px;
        line-height: 36px;
        font-size: 26px;
    }
    .summary {
        margin: 0 0 25px;
        font-size: 14px;
        &, p {
            line-height: 28px;
        }
        p:last-child {
            margin: 0;
        }
    }
}