.accordion {
  border-top: 1px solid rgba($primary-color, .13);
  .item {
    border-bottom: 1px solid rgba($primary-color, .13);
    &.active {
      .title-header:after {
        transform: rotate(0);
      }
    }
  }
  .title-header {
    font-size: 16px;
    padding: 15px 15px 15px 0;
    font-family: $third-ff;
    cursor: pointer;
    position: relative;
    @include media('>=large') {
      padding: 22px 20px 22px 0;
      font-size: 20px;
    }
    &:before, &:after {
      content: '';
      display: block;
      height: 2px;
      width: 11px;
      background-color: $secondary-color;
      position: absolute;
      top: calc(50% - 1px);
    }
    &:before {
      right: 0;
    }
    &:after {
      right: 0;
      transform: rotate(-90deg);
      transform-origin: center center;
      transition: transform .3s;
    }
  }
  .description {
    display: none;
    @include media('>=large') {
      padding-bottom: 23px;
      margin-top: -5px;
    }
    p {
      text-align: justify;
      @include media('>=tablet') {
        line-height: 30px;
        font-size: 16px;
      }
    }
  }

  //white theme
  &.white {
    border-top: 1px solid rgba($white, .13);
    .item {
      border-bottom: 1px solid rgba($white, .13);
    }
    .title-header {
      color: $white;
    }
    .description {
      p {
        color: #bdcce9;
      }
    }
  }
}