$fonts-path: '../fonts';
@font-face {
    font-family: 'Montserrat';
    src: url('#{$fonts-path}/Montserrat-Regular.eot');
    src: url('#{$fonts-path}/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts-path}/Montserrat-Regular.woff2') format('woff2'),
    url('#{$fonts-path}/Montserrat-Regular.woff') format('woff'),
    url('#{$fonts-path}/Montserrat-Regular.ttf') format('truetype'),
    url('#{$fonts-path}/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url('#{$fonts-path}/Montserrat-Medium.eot');
    src: url('#{$fonts-path}/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts-path}/Montserrat-Medium.woff2') format('woff2'),
    url('#{$fonts-path}/Montserrat-Medium.woff') format('woff'),
    url('#{$fonts-path}/Montserrat-Medium.ttf') format('truetype'),
    url('#{$fonts-path}/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('#{$fonts-path}/Oswald-Regular.eot');
    src: url('#{$fonts-path}/Oswald-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts-path}/Oswald-Regular.woff2') format('woff2'),
    url('#{$fonts-path}/Oswald-Regular.woff') format('woff'),
    url('#{$fonts-path}/Oswald-Regular.ttf') format('truetype'),
    url('#{$fonts-path}/Oswald-Regular.svg#Oswald-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald-Semibold';
    src: url('#{$fonts-path}/Oswald-SemiBold.eot');
    src: url('#{$fonts-path}/Oswald-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts-path}/Oswald-SemiBold.woff2') format('woff2'),
    url('#{$fonts-path}/Oswald-SemiBold.woff') format('woff'),
    url('#{$fonts-path}/Oswald-SemiBold.ttf') format('truetype'),
    url('#{$fonts-path}/Oswald-SemiBold.svg#Oswald-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}