.payment-wrap {
    padding-top: 70px;
    @include media(">=large") {
        padding-top: 127px;
    }
}

.payment-info {
    margin: 0 0 60px;
    @include media(">=large") {
        display: flex;
        justify-content: space-between;
        margin: 0 0 85px;
    }
    .payment-col {
        margin: 0 0 50px;
        @include media(">=large") {
            margin: 0;
        }
        .title {
            font-size: 26px;
            line-height: 30px;
            font-family: $third-ff;
        }
        &.make-mod {
            max-width: 464px;
            @include media(">=large") {
                min-width: 464px;
            }
        }
        &.question-mod {
            margin-top: 8px;
            max-width: 472px;
            @include media(">=large") {
                min-width: 472px;
                .title {
                    margin: 0 0 48px;
                    letter-spacing: -0.65px;
                }
            }
        }
        .info-row {
            margin: 0 0 32px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    .info-holder {
        @include media(">tablet") {
            display: flex;
            justify-content: space-between;
        }
        .info-holder-part {
            position: relative;
            margin: 0 0 30px;
            @include media(">tablet") {
                margin: 0;
            }
        }
        .info-dt {
            margin: 0 0 13px;
            font-size: 14px;
            line-height: 18px;
        }
        .info-dd {
            line-height: 40px;
            a {
                line-height: 24px;
            }
            .phone {
                display: block;
                font-size: 36px;
                line-height: 40px;
                font-weight: 500;
                &:before,
                &:after {
                    content: none;
                }
            }
        }
    }
    .make-form {
        position: relative;
        display: flex;
        //margin: 0 0 72px;
        margin:0 0 38px;
        align-items: flex-start;
        .button {
            margin: 0;
        }
        .field-wrap {
            flex: 1;
            margin: 0 26px 0 0;
            input {
                display: block;
                width: 100%;
                margin: 0;
            }
        }
    }
}