.pagination-wrap {
    position: relative;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
    font-size: 0;
    a, span {
        position: relative;
        top: 2px;
        width: 30px;
        display: inline-block;
        line-height: 24px;
        font-size: 16px;
        text-align: center;

    }
    a {
        @include transition(color);
        @include hover {
            color: $red-1;
        }
    }
    .page {
        //margin: 0 12px;
    }
    .current {
        color: $danger-color;
    }
    .previouspostslink,
    .nextpostslink {
        top: -1px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        svg {
            width: 8px;
            height: 10px;
            path {
                fill: $primary-color;
            }
        }
    }
    .previouspostslink {
        margin-right: 22px;
        svg {
            transform: rotate(90deg);
        }
    }
    .nextpostslink {
        margin-left: 20px;
        svg {
            transform: rotate(-90deg);
        }
    }

}
