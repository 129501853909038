.header {
    background-color: $white;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 20;
    min-width: 320px;
    @include media('>=large') {
        min-height: 88px;
        box-shadow: 0 3px 43px rgba(0, 0, 0, 0.06);
        display: flex;
        align-items: center;
    }
    .container {
        @include media('>=large') {
            width: 100%;
            @include ie {
                display: table;
                min-height: 88px;
            }
        }
    }
}

.header-content {
    @include media('>=large') {
        display: flex;
        justify-content: space-between;
        height: 100%;
        @include ie {
            display: table-cell;
            vertical-align: middle;
        }
    }
    >div {
        @include media('>=large') {
            display: flex;
            align-items: center;
            @include ie {
                float: right;
                &.main-logo {
                    float: left;
                }
            }
        }
    }
    .for-brokers {
        display: none;
        @include media('>=large') {
            margin-left: 26px;
            margin-right: 38px;
            display: block;
            line-height: 1.2;
            flex-shrink: 0;
        }
    }
    .make-payment {
        display: none;
        @include media('>=large') {
            margin-bottom: 0;
            margin-right: 35px;
            display: block;
            .header.auth-mod &{
                margin-left: 28px;
            }
        }
    }
    .login {
        position: relative;
        display: none;
        @include media('>=large') {
            align-items: center;
            display: flex;
        }
        .custom-link {
            margin-right: 13px;
            line-height: 1.2;
        }
        svg path {
            fill: $primary-color;
            @include transition(fill)
        }
    }
    .no-touch & {
        .login:hover {
            .custom-link:before,
            .custom-link:after {
                transform: scaleX(0);
            }
        }
    }
}

//logo

.main-logo {
    .desktop {
        display: none;
        @include media('>=xlarge') {
            width: 280px;
            height: 48px;
            display: block;
            margin-right: 26px;
            img {
                max-width: 100%;
            }
        }
    }
    .mobile {
        display: none;
        @include media('>=large') {
            width: 51px;
            height: 51px;
            display: block;
            margin-right: 20px;
            img {
                max-width: 100%;
            }
        }
        @include media('>=xlarge') {
            display: none;
        }
    }
}

//navigation

.navigation {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: $white;
    z-index: 10;
    text-align: center;
    overflow: auto;
    @include media('>=large') {
        display: flex !important;
        position: static;
        left: auto;
        top: auto;
        width: auto;
        height: auto;
        background-color: transparent;
        text-align: left;
        overflow: visible;
    }
    .nav-holder {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 110px 20px 220px;
        min-height: 100%;
        @include media('>=large') {
            padding: 0;
            display: block;
            min-height: 1px;
        }
    }
}

.main-nav {
    .title {
        color: #213766;
        margin-bottom: 20px;
        font-size: 30px;
        letter-spacing: -.75px;
        @include media('>=large') {
            display: none;
        }
    }
    ul {
        padding: 0;
        margin: 0;
    }
    li {
        padding-left: 0;
        &:before {
            display: none;
        }
        &.current-menu-item,
        &.current-menu-parent {
            > a {
                color: $primary-color;
                border-bottom: 1px solid $primary-color;
            }
            &:hover > a:before {
                max-width: 0;
            }
        }
    }
    a {
        color: $primary-color;
        font-weight: 500;
        cursor: pointer;
        position: relative;
        line-height: 1.4;
        display: inline-block;
        font-size: 16px;
        margin-bottom: 15px;
        @include media('>=large') {
            white-space: nowrap;
            font-size: 15px;
            display: block;
            bottom: 0;
            margin-bottom: 0;
        }
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            overflow: hidden;
            max-width: 0;
            color: $secondary-color;
            content: attr(data-hover);
            transition: max-width 0.5s;
            line-height: 1.4;
            display: block;
            white-space: nowrap;
        }
        .no-touch &:hover {
            &:before {
                max-width: 100%;
            }
        }
    }
    >ul {
        @include media('>=large') {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: baseline;
            @include ie {
                max-width: 540px;
            }
        }
        >li {
            position: relative;
            @include media('>=large') {
                padding: 16px 10px;
                min-height: 38px;
                display: flex;
                align-items: center;
            }
            &:hover {
                @include media('>=large') {
                    .sub-menu {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                    }
                }
            }
        }
    }
    .sub-menu {
        margin-bottom: 10px;
        display: none;
        @include media('>=large') {
            z-index: 1;
            display: block;
            background-color: $white;
            position: absolute;
            top: 100%;
            min-width: 100%;
            left: -12px;
            padding: 24px 37px 24px 28px;
            margin-bottom: 0;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-5%);
            transition-property: opacify, transform;
            transition: .3s ease;


        }
        &.open {
            display: block;
            @include media('>=large') {
                display: block;
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }
        >li {
            @include media('>=large') {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        a {
            font-size: 14px;
            white-space: nowrap;
            display: table;
        }
    }
}

//mobile

.mobile-login {
    background-color: $primary-color;
    margin: 0 -15px;
    padding: 8px 15px 12px;
    display: flex;
    justify-content: space-between;
    @include media('>=large') {
        display: none;
    }
    a {
        color: $white;
        line-height: 1.2;
        &:before,
        &:after {
            background-color: $white;
        }
    }
}

.mobile-nav {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    @include media('>=large') {
        display: none;
    }
    >div {
        display: flex;
    }
    .logo {
        height: 48px;
        width: 48px;
        display: block;
        img {
            max-width: 100%;
        }
    }
    .button {
        margin: 0;
    }
}

.mob-nav-btn {
    width: 53px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-color;
    @include media('>=large') {
        display: none;
    }
    >div {
        width: 20px;
        height: 12px;
        position: relative;
        span {
            width: 20px;
            height: 2px;
            display: block;
            position: absolute;
            background-color: $white;
            left: 0;
            transform: rotate(0deg);
            transform-origin: left center;
            transition: all .3s ease;
            &:nth-child(1) {
                top: 0;
            }
            &:nth-child(2) {
                top: 5px;
            }
            &:nth-child(3) {
                top: 10px;
            }
        }
    }

    &.open {
        span {
            &:nth-child(1) {
                transform: rotate(45deg);
                left: 3px;
                top: -2px;
            }
            &:nth-child(2) {
                width: 0;
                opacity: 0;
            }
            &:nth-child(3) {
                transform: rotate(-45deg);
                left: 3px;
                top: 12px;
            }
        }
    }
}

.close-nav {
    padding: 15px;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 1;
    @include media('>=large') {
        display: none;
    }
}

.header-social-icons {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 113px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    @include media('>=large') {
        display: none;
    }
    li {
        padding: 0 20px;
        line-height: 1;
        &:before {
            display: none;
        }
    }
    a {
        display: block;
        width: 24px;
    }
    img {
        vertical-align: top;
    }
}

.notification{
    position: relative;
    display: none;
    margin:0 40px 0 0;
    width: 22px;
    height: 22px;
    .mobile-login &{
        display: flex;
        margin: 2px auto 0 30px;
        width:auto;
        height:auto;
        .text{
            font-family: $second-ff;
            text-transform: uppercase;
            font-weight: 600;
            color: #fff;
            font-size: 20px;
        }
        .icon{
            position: absolute;
            left: -28px;
            top: 3px;
            width: 22px;
            height: 22px;
        }
        path{
            fill: #fff;
        }
    }
    @include media(">=large") {
        display: block;
        .mobile-login &{
            display: none;
        }
    }
}

.mob-login-link{
    display: flex;
    margin:0 0 0 30px;
    .icon{
        position: absolute;
        left: -28px;
        top: 3px;
        width: 22px;
        height: 22px;
        path{
            fill: #fff;
        }
    }
    @include media(">=large") {

    }
}

.mob-login-wrap,
.login-wrap{
    z-index: 10;
    position: relative;
    flex-shrink: 0;
    &:hover{
        .account-menu{
            pointer-events: auto;
            opacity: 1;
            transform: translateY(0);
        }
        .login path{
            fill: $secondary-color;
        }
        .custom-link:before,
        .custom-link:after {
            transform: scaleX(0);
        }
    }
}

.login-wrap{
    @include media('>=large') {
        padding: 16px 0;
    }
}

.mob-login-wrap{
    .account-menu{
        right: 0;
        left:auto;
        a{
            color: $primary-color;
        }
    }

}

.account-menu{
    position: absolute;
    top: calc(100% + 12px);
    left: auto;
    right: 0;
    padding: 24px;
    background: #fff;
    opacity: 0;
    transform: translateY(-5%);
    transition-property: opacity, transform ;
    transition: .3s ease;
    pointer-events: none;
    @include media(">=large") {
        top: 100%;
    }
    @media (min-width: 1280px) {
        width: 198px;
    }
    .item{
        line-height: 20px;
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .link{
        display: inline-block;
        font-size: 14px;
        position: relative;
        white-space: nowrap;
        &:before {
            position: absolute;
            top: 1px;
            left: 0;
            overflow: hidden;
            max-width: 0;
            color: $secondary-color;
            content: attr(data-hover);
            transition: max-width 0.5s;
            line-height: 1.4;
            display: block;
            white-space: nowrap;
        }
        .no-touch &:hover {
            &:before {
                max-width: 100%;
            }
        }
    }

}