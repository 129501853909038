
.company-stats{
    position: relative;

    padding-top: 50px;
    @include media(">=large") {
        display: flex;
        justify-content: space-between;
        padding-top: 80px;
    }
    .stats-item{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        @include media(">=large") {
            margin-bottom: 0;
        }
    }
    .icon{
        position: relative;
        margin:0 17px 0 0;
        &.years{
            width: 26px;
            height: 26px;
        }
        &.employees{
            width: 26px;
            height: 26px;
        }
        &.location{
            width: 20px;
            height: 26px;
            margin-right: 23px;
            @include media(">=large") {
                margin-right:17px;
            }
        }
    }
    .count{
        position: relative;
        margin:0 16px 0 0;
        font-family: $second-ff;
        font-size: 71px;
        font-weight: 500;
        line-height: 60px;
        letter-spacing: -1.75px;
    }
    .title{
        font-family: $second-ff;
        font-size: 20px;
        // font-weight: 500;
        font-weight: 600;
        line-height: 60px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }


}