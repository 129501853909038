.popular-img-post {
    position: relative;
    margin:0 0 32px;
    &:last-child{
        margin-bottom: 28px;
    }

    .post-picture{
        position: relative;
        width: 100%;
        height: 226px;
        .pic {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            @include bg(center, center, cover);
        }
    }

    .post-info{
        position: relative;
        padding: 43px 40px 30px;
        text-align: center;
        background: #fff;
        box-shadow: 0 0 20px rgba(5, 51, 103, 0.06);
    }




    .post-title{
        margin:0 0 23px;
        font-family: $fourth-ff;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.45px;
    }


}

.posts-all{
    .custom-link{
        font-size: 18px;
    }
}