$fonts-path: '../fonts';

@font-face {
  font-family: 'icomoon';
  src:  url('#{$fonts-path}/icomoon/icomoon.eot?fhbcp9');
  src:  url('#{$fonts-path}/icomoon/icomoon.eot?fhbcp9#iefix') format('embedded-opentype'),
    url('#{$fonts-path}/icomoon/icomoon.ttf?fhbcp9') format('truetype'),
    url('#{$fonts-path}/icomoon/icomoon.woff?fhbcp9') format('woff'),
    url('#{$fonts-path}/icomoon/icomoon.svg?fhbcp9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */  
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alert:before {
  content: "\e901";
}
.icon-arrow-down:before {
  content: "\e900";
}
