//select
.jcf-select {
  border: none;
  border-bottom: 1px solid rgba($light-blue, .25);
  background: $white;
  margin: 0 0 10px;
  min-width: 150px;
  height: auto;
  vertical-align: top;
  font-family: $third-ff;
  &.jcf-drop-active {
    .jcf-select-opener:after {
      transform: translateY(-50%) rotate(-180deg);
    }
  }
  &.jcf-select-small-select {
    .jcf-select-drop {
      ul li span {
        padding: 0 10px 0 25px;
      }
      .jcf-selected {
        //background-position: 5px 4px;
        &:before {
          left: 5px;
        }
      }
    }
  }
  .jcf-select-text {
    line-height: 1;
    margin: 0;
    padding: 0 20px 11px 0;
    height: 22px;
    color: $primary-color;
    font-size: 14px;
    font-weight: 500;
  }
  .jcf-select-opener {
    background: transparent;
    &:after {
      content: "\e900";
      font-family: 'icomoon';
      font-size: 6px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transform-origin: center center;
      color: $primary-color;
      transition: transform .3s ease;
    }
  }
  //drop
  .jcf-select-drop {
    top: calc(100% + 1px);
    box-shadow: 0 0 20px rgba(5, 51, 103, 0.06);
    padding: 25px 0;
    left: 0;
    right: 0;
    background-color: $white;
    .jcf-select-drop-content {
      border: none;
    }
    ul li {
      color: $light-gray;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      transition: color .3s ease;
      padding: 0;
      font-family: $third-ff;
      &:before {
        display: none;
      }
      &:last-child span {
        margin-bottom: 0;
      }
      span {
        padding: 0 20px 0 58px;
        color: $light-gray;
        text-overflow: ellipsis;
        margin-bottom: 14px;
        white-space: normal;
      }
    }
    .jcf-hover {
      background: transparent;
      color: $light-gray;
    }
    //.jcf-selected {
    //	color: $primary-color;
    //	background: {
    //		image: url(#{$images-path}/icons/check-icon.svg);
    //		repeat: no-repeat;
    //		position: 29px 4px;
    //	}
    //;
    //}
    .jcf-selected {
      position: relative;
      color: $primary-color;
      &:before {
        content: '';
        position: absolute;
        left: 29px;
        top: 4px;
        background: {
          image: url(#{$images-path}/icons/check-icon.svg);
          repeat: no-repeat;
          size: 14px;
        }
        display: block;
        width: 15px;
        height: 12px;
      }
    }
    .jcf-disabled {
      display: none;
    }
  }
  //scroll
  .jcf-scrollbar-vertical {
    width: 6px;
    right: 4px;
    .jcf-scrollbar-handle {
      width: 6px;
      background: $primary-color;
    }
    .jcf-scrollbar-dec,
    .jcf-scrollbar-inc {
      display: none;
      height: 0;
    }
  }
  .jcf-scrollbar-horizontal {
    display: none;
    height: 0;
  }
}

select.wpcf7-not-valid {
  + .jcf-select,
  .jcf-select .jcf-select-drop-content {
    border-bottom: 1px solid $danger-color !important;
    color: $danger-color !important;
    .jcf-select-opener:after {
      color: $danger-color;
    }
  }
}

//checkbox
.jcf-checkbox {
  width: 30px;
  height: 30px;
  border: 1px solid $gray-5;
  border-radius: 50%;
  vertical-align: top;
  background: $white;
  margin: 2px 5px 0 0;
  position: relative;
  @include media('>=tablet') {
    width: 15px;
    height: 15px;
  }
  &.jcf-checked {
    background-image: url(#{$images-path}/icons/check_mark_act.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  span {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    border-radius: 50%;
    background: transparent;
  }
}

// radio
.jcf-radio {
  width: 30px;
  height: 30px;
  border: 1px solid $gray-5;
  border-radius: 50%;
  vertical-align: top;
  background: $white;
  margin: 2px 5px 0 0;
  position: relative;
  @include media('>=tablet') {
    width: 15px;
    height: 15px;
  }
  &.jcf-checked {
    span {
      opacity: 1;
    }
  }
  span {
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    border-radius: 50%;
    background: $gray-5;
    opacity: 0;
    @include media('>=tablet') {
      top: 3px;
      right: 3px;
      bottom: 3px;
      left: 3px;
    }
  }
}

//file
.jcf-file {
  background: transparent;
  vertical-align: top;
  .jcf-upload-button {
    display: none;
  }
  .jcf-fake-input {
    text-overflow: ellipsis;
    max-width: 125px;
    width: auto;
    padding: 0;
    border: none;
    line-height: 1;
    height: auto;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: $second-ff;
    color: $secondary-color;
    position: relative;
    cursor: pointer;
    &:before {
      content: '';
      display: inline-block;
      vertical-align: top;
      margin-right: 6px;
      width: 17px;
      height: 18px;
      background: {
        image: url(../images/icons/attachment.svg);
        repeat: no-repeat;
      }
    ;
    }
  }
}

.form-group, .input-wrap, .field-wrap {
  position: relative;
  * {
    position: relative;
  }
  .active {
    .floating-label {
      font-size: 16px;
      top: -24px;
      @include media('>=large') {
        font-size: 18px;
      }
    }
  }
  .with-label {
    .active .floating-label, &.active .floating-label {
      font-size: 14px;
      top: 12px;
      opacity: 0;
      //transition: opacity .3s ease;
    }
  }
}

.floating-label {
  font-size: 13px;
  position: absolute;
  left: 0;
  top: 12px;
  transition: all .3s ease;
  line-height: 1;
  color: $light-blue;
  .with-label & {
    transition: opacity .3s ease;
  }
}

//.form-group, .input-wrap, .field-wrap {
//	position: relative;
//	* {
//		position: relative;
//	}
//	.active {
//		.floating-label {
//			//font-size: 16px;
//			transform: translate3d(0,0,0) scale(1.1);
//			top: -24px;
//			@include media('>=large') {
//				//font-size: 18px;
//				transform: translate3d(0,0,0) scale(1.2);
//			}
//		}
//	}
//	.with-label {
//		.active .floating-label, &.active .floating-label {
//			font-size: 14px;
//			top: 12px;
//			opacity: 0;
//			//transition: opacity .3s ease;
//		}
//	}
//}
//
//.floating-label {
//	font-size: 14px;
//	position: absolute;
//	left: 0;
//	top: 12px;
//	transition: all .3s ease;
//	line-height: 1;
//	color: $light-blue;
//	transform: translate3d(0,0,0) scale(1);
//	will-change: transform;
//	backface-visibility: hidden;
//	transform-origin: left center;
//	.with-label & {
//		transition: opacity .3s ease;
//	}
//}

//form grid

.form-holder {
  .form-group {
    margin: 0 -10px;
    display: flex;
    flex-wrap: wrap;
    @include media('>=tablet') {
      margin: 0 -17px;
      flex-wrap: inherit;
      align-items: flex-end;
    }
    h4, .h4 {
      margin-bottom: 35px;
      font-family: $main-ff;
      font-size: 18px;
      @include media('>=large') {
        font-size: 20px;
      }
    }
    p {
      font-size: 16px;
      margin-bottom: 40px;
    }
    p, h2, h3 {
      text-align: center;
      @include media('>=tablet') {
        text-align: left;
      }
    }
  }

  .align-top {
    @include media('>=tablet') {
      align-items: flex-start;
    }
  }

  .align-bottom {
    @include media('>=tablet') {
      align-items: flex-end;
    }
  }

  .four-fields {
    .inline-block {
      width: 100%;
      @include media('>=tablet') {
        width: auto;
      }
    }
  }

  .holder {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: flex-end;
    .step6 & {
      align-items: flex-start;
    }
    @include media('>=tablet') {
      width: 50%;
    }
    &.three-fields {
      @include media('>=tablet') {
        width: 33.33%;
      }
    }
  }
  .inline-block {
    letter-spacing: 0;
    padding: 0 10px;
    flex-grow: 1;
    @include media('>=tablet') {
      padding: 0 17px;
    }
    &.small-block {
      flex-grow: 0;
    }
    &.medium-block {
      @include media('>=tablet') {
        max-width: 130px;
      }
    }
  }
  .inline-input {
    > label {
      display: inline-block;
      margin-right: 50px;
    }
  }
  .with-textarea {
    label {
      margin: 0 0 7px;
    }
  }
  .jcf-select {
    width: 100%;
  }
  .small-block {
    input, select, .jcf-select {
      width: 69px;
      min-width: 69px;
    }
  }
  .form-control {
    width: 100%;
  }
  .no-margin {
    .inline-block {
      margin-bottom: 0;
    }
  }
  .top-margin {
    margin-top: -14px;
    @include media(">=large") {
      margin-top: -28px;
    }
    .step3 & {
      @include media(">=large") {
        margin-top: -20px;
      }
    }
  }
  .b-margin {
    margin-bottom: 35px;
  }
  .b-margin-small {
    margin-bottom: 25px;
  }
  .open-block {
    display: none;
    &.open {
      display: flex;
    }
  }

  .clear {
    margin-left: 23px;
    margin-right: 15px;
    line-height: 1.2;
    margin-top: 21px;
  }

  //with left label
  .with-left-label {
    .sub-group {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .holder {
        @include media('>=tablet') {
          max-width: 275px;
        }
      }
    }
    .total-count {
      font-family: $second-ff;
      font-size: 36px;
      font-weight: 500;
      &:before {
        content: '$';
        display: inline-block;
        vertical-align: middle;
        font-family: $third-ff;
        font-size: 14px;
        margin-right: 10px;
        color: $light-blue;
      }
    }
    .left-label {
      width: 100%;
      display: block;
      font-size: 18px;
      font-family: $third-ff;
      color: $light-blue;
      margin-bottom: 10px;
      padding-left: 10px;
      @include media('>=tablet') {
        max-width: 200px;
        font-size: 14px;
        padding-left: 17px;
        margin-bottom: 53px;
      }
      &.total-label {
        @include media('>=tablet') {
          font-size: 18px;
        }
      }
    }

    //large label
    &.large-label {
      .left-label {
        @include media('>=tablet') {
          max-width: 267px;
          margin-bottom: 62px;
        }
      }
      .inline-block {
        @include media('>=tablet') {
          max-width: 407px;
        }
      }
      .total-count {
        &:before {
          display: none;
        }
      }
    }
  }

  //inline fields

  .inline-fields {
    display: flex;
    align-items: flex-end;
    .large {
      margin-right: 18px;
      width: 100%;
      @include media('>=tablet') {
        max-width: 116px;
        margin-right: 27px;
        padding: 0;
        label {
          white-space: nowrap;
        }
      }
    }
    .link-for-modal {
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      margin-bottom: -5px;
      a {
        display: block;
      }
      img {
        width: 100%;
        height: 100%;
      }
      @include media('>=tablet') {
        display: none;
      }
    }
    .medium {
      max-width: 104px;
      margin-right: 26px;
    }

    .small-group {
      display: none;
      @include media('>=tablet') {
        display: flex;
        align-items: flex-end;
      }
      .delete {
        max-width: 20px;
        max-height: 20px;
      }
    }
    .small {
      max-width: 82px;
      margin-right: 11px;
      &:last-child {
        margin-right: 0;
        margin-bottom: 10px;
        min-width: 20px;
        height: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

//label

.form-label {
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 33px;
  font-family: $third-ff;
  color: $light-blue;
  @include media(">=tablet") {
    line-height: 20px;
  }
  &.large-label {
    font-size: 16px;
  }
}

//label with tooltip

.with-tooltip {
  display: flex;
  justify-content: space-between;
  &.inline-tooltip {
    @include media('>=large') {
      display: block;
      .tooltip {
        margin: 0 0 0 15px;;
      }
      img {
        margin-bottom: -1px;
      }
    }
  }
  .tooltip {
    display: inline-block;
    margin: 2px -4px 0 20px;
    flex-shrink: 0;
    position: relative;
    width: 16px;
    height: 16px;
    &:hover {
      &:before, &:after {
        display: block;
      }
    }
    img {
      height: 16px;
      width: 16px;
    }
    &:before {
      content: attr(data-tooltip);
      padding: 15px;
      box-shadow: 0 0 20px rgba(5, 51, 103, 0.06);
      position: absolute;
      bottom: calc(100% + 15px);
      left: auto;
      right: -10px;
      z-index: 5;
      font-family: $third-ff;
      color: rgba($primary-color, .65);
      font-size: 14px;
      line-height: 22px;
      background-color: $white;
      min-width: 320px;
      display: none;
      @include media('>=large') {
        padding: 27px 30px;
      }
      @include media('>=xlarge') {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
      }
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $white;
      position: absolute;
      left: calc(50% - 10px);
      bottom: calc(100% + 5px);
      display: none;
      z-index: 5;
    }
    &.small-tooltip {
      &:before {
        min-width: 204px;
      }
    }
  }
}