.jcf-radio {
  border-radius: 9px
}

.jcf-radio span {
  display: none;
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  background: #777;
  border-radius: 100%
}

.jcf-radio input[type=radio] {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0;
  margin: 0;
  left: 0;
  top: 0
}

.jcf-radio.jcf-checked span {
  display: block
}

.jcf-checkbox, .jcf-radio {
  vertical-align: middle;
  display: inline-block;
  position: relative;
  overflow: hidden;
  cursor: default;
  background: #fff;
  border: 1px solid #777;
  margin: 0 3px 0 0;
  height: 16px;
  width: 16px
}

.jcf-checkbox span {
  position: absolute;
  display: none;
  height: 4px;
  width: 8px;
  top: 50%;
  left: 50%;
  margin: -7px 0 0 -6px;
  border: 3px solid #777;
  border-width: 0 0 3px 3px;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.7071067811865483, M12=0.7071067811865467, M21=-0.7071067811865467, M22=0.7071067811865483, SizingMethod='auto expand')"
}

:root .jcf-checkbox span {
  margin: -4px 0 0 -5px
}

.jcf-checkbox input[type=checkbox] {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  left: 0;
  top: 0
}

.jcf-checkbox.jcf-checked span {
  display: block
}

.jcf-button, .jcf-file {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background: #fff
}

.jcf-button {
  vertical-align: top;
  border: 1px solid #777;
  margin: 0 10px 10px 0;
  padding: 5px 10px
}

.jcf-button .jcf-real-element {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  outline: none;
  opacity: 0;
  padding: 0;
  margin: 0;
  border: 0;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0
}

.jcf-file {
  white-space: nowrap
}

.jcf-file .jcf-real-element {
  position: absolute;
  font-size: 200px;
  height: 200px;
  margin: 0;
  right: 0;
  top: 0
}

.jcf-file .jcf-fake-input, .jcf-file .jcf-upload-button {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #777;
  line-height: 28px;
  height: 28px
}

.jcf-file .jcf-fake-input {
  text-overflow: ellipsis;
  font-size: 13px;
  width: 125px;
  padding: 0 8px
}

.jcf-file .jcf-upload-button {
  margin: 0 0 0 -1px;
  padding: 0 10px
}

.jcf-scrollable-wrapper {
  box-sizing: content-box;
  position: relative
}

.jcf-scrollbar-vertical {
  position: absolute;
  cursor: default;
  background: #e3e3e3;
  width: 14px;
  bottom: 0;
  right: 0;
  top: 0
}

.jcf-scrollbar-vertical .jcf-scrollbar-dec, .jcf-scrollbar-vertical .jcf-scrollbar-inc {
  background: #bbb;
  height: 14px;
  width: 14px;
  left: 0;
  top: 0
}

.jcf-scrollbar-vertical .jcf-scrollbar-inc {
  top: auto;
  bottom: 0
}

.jcf-scrollbar-vertical .jcf-scrollbar-handle {
  background: #888;
  height: 1px;
  width: 14px
}

.jcf-scrollbar-horizontal {
  position: absolute;
  background: #e3e3e3;
  right: auto;
  top: auto;
  left: 0;
  bottom: 0;
  width: 1px;
  height: 14px
}

.jcf-scrollbar-horizontal .jcf-scrollbar-dec, .jcf-scrollbar-horizontal .jcf-scrollbar-inc {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  background: #bbb;
  height: 14px;
  width: 14px
}

.jcf-scrollbar-horizontal .jcf-scrollbar-inc {
  left: auto;
  right: 0
}

.jcf-scrollbar-horizontal .jcf-scrollbar-slider {
  display: inline-block;
  position: relative;
  height: 14px
}

.jcf-scrollbar-horizontal .jcf-scrollbar-handle {
  position: absolute;
  background: #888;
  height: 14px
}

.jcf-scrollbar.jcf-inactive .jcf-scrollbar-handle {
  visibility: hidden
}

.jcf-scrollbar.jcf-inactive .jcf-scrollbar-dec, .jcf-scrollbar.jcf-inactive .jcf-scrollbar-inc {
  background: #e3e3e3
}

.jcf-select {
  display: inline-block;
  vertical-align: top;
  position: relative;
  border: 1px solid #777;
  background: #fff;
  margin: 0 0 12px;
  min-width: 150px;
  height: 26px
}

.jcf-select select {
  z-index: 1;
  left: 0;
  top: 0
}

.jcf-select .jcf-select-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
  display: block;
  font-size: 13px;
  line-height: 26px;
  margin: 0 35px 0 8px
}

.jcf-select .jcf-select-opener {
  position: absolute;
  text-align: center;
  background: #aaa;
  width: 26px;
  bottom: 0;
  right: 0;
  top: 0
}

body > .jcf-select-drop {
  position: absolute;
  margin: -1px 0 0;
  z-index: 9999
}

body > .jcf-select-drop.jcf-drop-flipped {
  margin: 1px 0 0
}

.jcf-select .jcf-select-drop {
  position: absolute;
  margin-top: 0;
  z-index: 9999;
  top: 100%;
  left: -1px;
  right: -1px
}

.jcf-select .jcf-drop-flipped {
  bottom: 100%;
  top: auto
}

.jcf-select.jcf-compact-multiple {
  max-width: 220px
}

.jcf-select.jcf-compact-multiple .jcf-select-opener:before {
  display: inline-block;
  padding-top: 2px;
  content: '...'
}

.jcf-select-drop .jcf-select-drop-content {
  border: 1px solid red
}

.jcf-select-drop.jcf-compact-multiple .jcf-hover {
  background: 0 0
}

.jcf-select-drop.jcf-compact-multiple .jcf-selected {
  background: #e6e6e6;
  color: #000
}

.jcf-select-drop.jcf-compact-multiple .jcf-selected:before {
  display: inline-block;
  content: '';
  height: 4px;
  width: 8px;
  margin: -7px 5px 0 -3px;
  border: 3px solid #777;
  border-width: 0 0 3px 3px;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.7071067811865483, M12=0.7071067811865467, M21=-0.7071067811865467, M22=0.7071067811865483, SizingMethod='auto expand')"
}

.jcf-list-box {
  overflow: hidden;
  display: inline-block;
  border: 1px solid #b8c3c9;
  min-width: 200px;
  margin: 0 15px
}

.jcf-list, .jcf-list .jcf-list-content {
  vertical-align: top;
  display: inline-block;
  width: 100%
}

.jcf-list {
  position: relative;
  background: #fff;
  line-height: 14px;
  font-size: 12px
}

.jcf-list .jcf-list-content {
  overflow: auto
}

.jcf-list ul {
  list-style: none;
  padding: 0;
  margin: 0
}

.jcf-list .jcf-option, .jcf-list ul li {
  overflow: hidden;
  display: block
}

.jcf-list .jcf-overflow {
  overflow: auto
}

.jcf-list .jcf-option {
  white-space: nowrap;
  cursor: default;
  padding: 5px 9px;
  color: #656565;
  min-height: 14px;
  height: 1%
}

.jcf-list .jcf-disabled {
  background: #fff !important;
  color: #aaa !important
}

.jcf-list-box .jcf-selected, .jcf-select-drop .jcf-hover {
  background: #e6e6e6;
  color: #000
}

.jcf-list .jcf-optgroup-caption {
  white-space: nowrap;
  font-weight: 700;
  display: block;
  padding: 5px 9px;
  cursor: default;
  color: #000
}

.jcf-list .jcf-optgroup .jcf-option {
  padding-left: 30px
}

.jcf-textarea {
  border: 1px solid #b8c3c9;
  box-sizing: content-box;
  display: inline-block;
  position: relative
}

.jcf-textarea .jcf-scrollbar-horizontal {
  display: none;
  height: 0
}

.jcf-textarea textarea {
  padding: 8px 10px;
  border: none;
  margin: 0
}

.jcf-textarea .jcf-resize {
  position: absolute;
  text-align: center;
  cursor: se-resize;
  background: #e3e3e3;
  font-weight: 700;
  line-height: 15px;
  text-indent: 1px;
  font-size: 12px;
  height: 15px;
  width: 14px;
  bottom: 0;
  right: 0
}

.jcf-textarea .jcf-resize:before {
  border: 1px solid #000;
  border-width: 0 1px 1px 0;
  display: block;
  margin: 4px 0 0 3px;
  width: 6px;
  height: 6px;
  content: ''
}

.jcf-number {
  display: inline-block;
  position: relative;
  height: 32px
}

.jcf-number input {
  -moz-appearance: textfield;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #777;
  padding: 3px 27px 3px 7px;
  margin: 0;
  height: 100%
}

.jcf-number input::-webkit-inner-spin-button, .jcf-number input::-webkit-outer-spin-button {
  -webkit-appearance: none
}

.jcf-number .jcf-btn-dec, .jcf-number .jcf-btn-inc {
  position: absolute;
  background: #aaa;
  width: 20px;
  height: 15px;
  right: 1px
}

.jcf-number .jcf-btn-inc {
  top: 1px
}

.jcf-number .jcf-btn-dec {
  top: auto;
  bottom: 1px
}

.jcf-number .jcf-btn-dec:hover, .jcf-number .jcf-btn-inc:hover {
  background: #e6e6e6
}

.jcf-number.jcf-disabled .jcf-btn-dec:hover, .jcf-number.jcf-disabled .jcf-btn-inc:hover {
  background: #aaa
}

.jcf-number .jcf-btn-dec:before, .jcf-number .jcf-btn-inc:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  border: 4px solid #aaa
}

.jcf-number .jcf-btn-inc:before {
  margin: -6px 0 0 -4px;
  border-color: transparent transparent #000 transparent
}

.jcf-number .jcf-btn-dec:before {
  margin: -1px 0 0 -4px;
  border-color: #000 transparent transparent transparent
}

.jcf-number .jcf-btn-dec.jcf-disabled:before, .jcf-number .jcf-btn-inc.jcf-disabled:before, .jcf-number.jcf-disabled .jcf-btn-dec:before, .jcf-number.jcf-disabled .jcf-btn-inc:before {
  opacity: .3
}

.jcf-number.jcf-disabled input {
  background: #ddd
}

.jcf-range {
  display: inline-block;
  min-width: 200px;
  margin: 0 10px;
  width: 130px
}

.jcf-range .jcf-range-track {
  margin: 0 20px 0 0;
  position: relative;
  display: block;
  height: 10px
}

.jcf-range .jcf-range-wrapper {
  position: relative;
  background: #e5e5e5;
  border-radius: 5px;
  display: block;
  margin: 5px 0
}

.jcf-range.jcf-vertical {
  min-width: 0;
  width: auto
}

.jcf-range.jcf-vertical .jcf-range-wrapper {
  margin: 0;
  width: 10px;
  height: auto;
  padding: 20px 0 0
}

.jcf-range.jcf-vertical .jcf-range-track {
  height: 180px;
  width: 10px
}

.jcf-range.jcf-vertical .jcf-range-handle {
  left: -5px;
  top: auto
}

.jcf-range .jcf-range-display-wrapper {
  position: absolute;
  min-height: 10px;
  min-width: 10px;
  left: 10px;
  right: 10px
}

.jcf-range .jcf-range-display {
  border-radius: 5px;
  position: absolute;
  background: #888;
  width: 10px;
  height: 10px
}

.jcf-range .jcf-range-display.jcf-range-min {
  margin: 0 0 0 -10px;
  padding: 0 0 0 10px
}

.jcf-range .jcf-range-display.jcf-range-max {
  margin: 0 -10px 0 0;
  padding: 0 10px 0 0
}

.jcf-range.jcf-vertical .jcf-range-display-wrapper {
  left: auto;
  right: auto;
  top: 10px;
  bottom: 10px
}

.jcf-range.jcf-vertical .jcf-range-display.jcf-range-min {
  margin: 0 0 -10px;
  padding: 0 0 10px
}

.jcf-range.jcf-vertical .jcf-range-display.jcf-range-max {
  margin: -10px 0 0;
  padding: 10px 0 0
}

.jcf-range .jcf-range-handle {
  position: absolute;
  background: #aaa;
  border-radius: 19px;
  width: 19px;
  height: 19px;
  margin: -4px 0 0;
  z-index: 1;
  top: 0;
  left: 0
}

.jcf-range .jcf-range-mark {
  position: absolute;
  overflow: hidden;
  background: #000;
  width: 1px;
  height: 3px;
  top: -7px;
  margin: 0 0 0 9px
}

.jcf-range.jcf-vertical .jcf-range-mark {
  margin: 0 0 9px;
  left: 14px;
  top: auto;
  width: 3px;
  height: 1px
}

.jcf-range .jcf-active-handle {
  z-index: 2
}

.jcf-range.jcf-focus .jcf-active-handle {
  border: 1px solid red;
  margin: -5px 0 0 -1px
}

.jcf-range.jcf-disabled {
  background: 0 0 !important;
  opacity: .3
}

.jcf-disabled {
  background: #ddd !important
}