.sidebar-nav {
  max-width: 480px;
  margin: 20px auto 44px;
  @include media('>=tablet') {
    margin: -75px auto 44px;
  }
  @include media('>=large') {
    margin: -105px 0 74px;
  }
  .title {
    height: 75px;
    background-color: $dark-blue;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    color: $white;
    font-family: $second-ff;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    @include media('>=large') {
      height: 105px;
      padding: 20px 50px;
    }
  }
  .nav-list {
    padding: 30px;
    background-color: $primary-color;
    @include media('>=large') {
      //padding: 53px 62px 61px;
      padding:53px 62px 61px 46px;
    }
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      padding-left: 0;
      line-height: 1.2;
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        display: none;
      }
      &.current-menu-item,
      &.active {
        a:after {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          bottom: -4px;
          height: 2px;
          display: block;
          background-color: $white;
        }
      }
    }
    a {
      color: $white;
      position: relative;
      font-weight: 500;
    }
  }
  &.without-hero{
    margin-bottom: 50px;
    @include media('>=tablet') {
      margin-top: 0;
    }
    @include media('>=large') {
      margin-top: 0;
    }
  }

  //with icons
  &.with-icons {
    margin-bottom: 25px;
    .nav-list {
      @include media('>=large') {
        padding: 53px 40px 52px 52px;
      }
      li {
        margin-bottom: 22px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .pic {
      width: 28px;
      height: 20px;
      margin-right: 22px;
      text-align: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    a {
      display: flex;
      align-items: center;
    }
  }
}