.not-found-block {
    position: relative;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    @include media(">=desktop") {
        padding-bottom:100px;
        padding-top: 60px;
    }
    .title{
        margin:0 0 22px;
        font-family: $fourth-ff;
        font-size: 26px;
        line-height: 30px;
    }
    .number {
        position: relative;
        display: inline-block;
        font-family: $fourth-ff;
        margin: 0 0 20px;
        font-size: 160px;
        line-height:.87;
         @include media(">tablet") {
             font-size: 230px;
         }
    }
    .note {
        margin:0 0 32px;
        font-family: $third-ff;
        font-size: 16px;
        line-height: 26px;
    }

}