.insurance-intro {
    position: relative;
    text-align: center;
    .subtitle {
        margin: 0 auto 65px;
        max-width: 594px;
        font-size: 18px;
        line-height: 28px;
    }
}

.insurances-wrap {
    margin-bottom: 110px;
}

.insurance-section {
    z-index: 1;
    position: relative;
    max-width: $large-width;
    margin: 0 auto;
    overflow: hidden;
    @include media(">=desktop") {
        max-width: none;
    }
    &.truck {
        z-index: 5;
        overflow: visible;
        .insurance-img {
            &:before {
                z-index: 10;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                background: #fff;
                width: 150px;
                content: "";
                @include media(">=desktop") {
                    content: none;
                }
            }
        }
        & + .insurance-section {
            @include media(">=large") {
                margin-top: -97px;
            }
            .insurance-inner {
                overflow: visible;
                @include media(">=large") {
                    padding-top: 214px;
                    padding-bottom: 50px;
                }
            }
        }
    }
}

.insurance-section:nth-child(odd) {
    &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: "";
        background: $dark-blue;
    }
    .insurance-img {
        order: 1;
        left: -150px;
        @include media(">=desktop") {
            left: 0;
        }
    }
}

.insurance-section:nth-child(even) {
    background: #fff;
    .insurance-img {
        right: -150px;
        @include media(">=desktop") {
            right: 0;
        }
    }
}

.insurance-inner {
    position: relative;
    max-width: $large-width;
    margin: 0 auto;
    order: 1;
    padding: 60px 15px;
    min-height: 0;
    p {
        font-size: 16px;
        line-height: 30px;
    }
    .insurance-section:nth-child(even) & {
        @include media(">=large") {
            padding-left: 15px;
        }
        @include media(">=xlarge") {
            padding-left: 140px;

        }
    }
    .insurance-section:nth-child(odd) & {
        order: 2;
        color: #fff;
        @include media(">=large") {
            padding-right: 15px;
            padding-left: 600px;

        }
        @include media(">=xlarge") {
            padding-right: 132px;
        }
        @include media(">=desktop") {
            padding-right: 144px;
            padding-left: 590px;
        }
    }

    @include media(">=large") {
        padding: 116px 0 128px;
        min-height: 770px;
        .insurance-section.truck & {
            min-height: 765px;
        }

    }
    @include media(">=desktop") {
        max-width: $desktop-width;
        padding: 177px 0 180px;
    }
}

.ins-content {
    @include media(">=large") {
        max-width: 438px;

    }
    h2 {
        line-height: 46px;
        margin-bottom: 24px;

    }
    .button {
        margin: 0;
    }
}

.insurance-img {
    position: absolute;
    order: 2;
    top: 0;
    height: 100%;
    display: none;
    pointer-events: none;
    @include media(">=large") {
        display: block;
    }
    img {
        display: block;
    }
}

.ins-text {
    margin-bottom: 42px;
    p:last-child {
        margin-bottom: 0;
    }
}

.advantages-block {
    position: relative;
    margin-bottom: 34px;
    &.details-mod {
        padding-top: 70px;
        padding-bottom: 28px;
        .advantages-list {
            @include media(">=xlarge") {
                justify-content: space-between;
                .item {
                    max-width: 126px;
                }
            }
        }
        .text {
            color: $light-blue;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 1px;
        }
    }
}

.advantages-list {
    position: relative;
    @include media(">=xlarge") {
        display: flex;

    }
    .item {
        margin: 0 0 15px;
        padding: 0 0 0 31px;
        &:before {
            content: none;
        }
        @include media(">=xlarge") {
            margin-left: 20px;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                span {
                    display: block;
                    &:last-child {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
    .text {
        text-transform: uppercase;
        font-family: $second-ff;
        font-weight: 600;
        line-height: 18px;
        font-size: 12px;
        letter-spacing: 0.8px;
    }
    .icon {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -11px;
        width: 21px;
        height: 21px;
        .insurance-section:nth-child(odd) & .st0 {
            fill: #fff;
        }
    }
}

.get-quote-block {
    position: relative;
    padding-bottom: 35px;
    &.details-mod {
        padding: 114px 0 92px;
    }
    @include media(">=large") {
        display: flex;
        align-items: flex-end;

    }

    .title-ask {
        margin: 0 0 12px;
        font-size: 18px;
        line-height: 20px;
    }

    .title {
        margin: 0 0 15px;
        line-height: 1.11;
        font-size: 29px;
        @include media(">=tablet") {
            font-size: 54px;
        }
        @include media(">=large") {
            margin: 0;
        }
    }

    .button {
        margin: 6px 0;
        @include media(">=large") {
            margin-left: 45px;
        }
    }
    .phone {
        margin: 0 0 15px;
        font-family: $second-ff;
        font-size: 54px;
        font-weight: 400;
        line-height: 60px;
        @include media(">=large") {
            margin: 0 0 0 auto;
        }
    }
}
