.hero-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  background-color: $primary-color;
  @include bg(center, center, cover);
  padding: 50px 20px;
  @include media('>=tablet') {
    padding: 100px 20px;
  }
  @include media('>=large') {
    //min-height: 510px;
    padding: 120px 20px;
  }
  //@include media('>=desktop') {
  //  min-height: 710px;
  //}
  //@include media('>=fullhd') {
  //  min-height: 900px;
  //}
  @include ie {
    display: table;
    width: 100%;
    .content {
      display: table-cell;
      vertical-align: middle;
    }
  }
  h1, .h1 {
    color: $white;
    max-width: 350px;
    text-align: center;
    margin: 0 auto;
    @include media('>=large') {
      max-width: 450px;
    }
  }
}