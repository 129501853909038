.socials-colors{
    ul li{
        &:before{
            content: none;
        }
    }
    a {
        &.facebook {
            path {
                fill: #21579A;
            }
        }
        &.twitter {
            path {
                fill: #1DA0F1;
            }
        }
        &.linkedin {
            path {
                fill: #007ABC;
            }
        }
        &.print{
            width: 20px;
            height: 22px;
        }
        &.instagram {
            span {
                @include transition(opacity);
                &.default {
                    display: none;
                }
                &.hovered {
                    z-index: 1;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    @include show;
                    svg .st1 {
                        fill: #ffffff;
                    }

                }
            }


        }
    }
    & > a{
        margin-left: 18px;
    }
}



