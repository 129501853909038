.how-list {
    @include media(">tablet") {
        display: flex;
    }
    .item {
        @include media(">tablet") {
            margin: 0 0 0 108px;
        }
        &:first-child {
            margin: 0;
        }
    }
}