.custom-form-wrap {
    padding-top: 70px;
    padding-bottom: 40px;
    @include media(">=large") {
        padding-top: 126px;
        padding-bottom: 140px;
    }
}
.custom-col-group,
.custom-form-info {
    margin: 0 0 60px;
    padding: 0;
    @include media(">=large") {
        display: flex;
        justify-content: space-between;
        margin: 0;
        &.block-mod{
            display: block;
        }
    }
    .info-holder {
        @include media(">tablet") {
            display: flex;
            justify-content: space-between;
        }
        .info-holder-part {
            position: relative;
            margin: 0 0 30px;
            @include media(">tablet") {
                margin: 0;
            }
        }
        .info-dt {
            margin: 0 0 13px;
            font-size: 14px;
            line-height: 18px;
        }
        .info-dd {
            line-height: 40px;
            a {
                line-height: 24px;
            }
            .phone {
                display: block;
                font-size: 36px;
                line-height: 40px;
                font-weight: 500;
                &:before,
                &:after {
                    content: none;
                }
            }
        }
    }
    .sign-up-row{
        margin-bottom: 30px;
        @include media(">tablet") {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 46px;
        }
        .title{
            position: relative;
            margin: 0;
            font-size: 26px;
            line-height: 32px;
            letter-spacing: -0.65px;
            .icon{
                position: absolute;
                left: -39px;
                top: 0px;
                width: 20px;
                height: 20px;
                path{
                    fill: $red-1;
                }

            }
        }
        .custom-link{
            font-size: 20px;
            line-height: 24px;
        }
    }
    .field-wrap{
        margin-bottom: 43px;
        &.one-mod{
            width: 100%;
             @include media(">tablet") {
                 width: calc(50% - 24px);

             }
        }
        &.group-mod{
            margin-bottom: 47px;
            @include media(">tablet") {
                display: flex;
            }
            .inline-block{
                width: 100%;
                @include media(">tablet") {
                     width:calc(50% - 24px);

                 }
            }
        }
        &.button-mod{
            display: flex;
            margin:0;
            align-items: center;
            .button{
                margin: 0 22px 0 0;
            }
        }

        .custom-link{
            line-height: 26px;
        }
    }
    .inline-block{
        margin-bottom: 0;
    }
    .form-control{
        width: 100%;
        margin:0;
    }
    .choice{
        position: relative;
        width: 12px;
        margin: 18px 0 14px;
        color: rgba(#193364, .65);
        line-height: 37px;
        font-size: 14px;
        @include media(">tablet") {
            top: 17px;
            margin: 0 18px;
            font-size: 12px;

        }
    }
}

.custom-col {
    margin: 0 0 50px;
    @include media(">=large") {
        margin: 0;
    }
    &.form-mod{
        max-width: 571px;
        @include media(">=large") {
            width: 49%;
        }
    }
    &.report-mod{
        max-width: 504px;
        @include media(">=large") {
            width: 43%;
        }
        .field-wrap{
            margin-bottom:59px;
            &.verify-mod{
                margin-bottom: 35px;
            }
            &.text-mod{
                margin-bottom: 32px;
            }
            &.note-mod{
                margin-bottom:33px;
            }
        }
    }
    &.info-mod{
        max-width: 478px;
        @include media(">=large") {
            width: 41%;
            padding-top: 8px;
        }
    }
    &.info-mod-2{
        max-width: 500px;
        @include media(">=large") {
            width: 42%;
        }
    }
    .info-row {
        margin: 0 0 32px;
        font-size: 16px;
        line-height: 20px;
    }
}
