// variables
$images-path: '../images';

// colors
$primary-color: #193364;
$secondary-color: #d11938;

$white: #fff;
$gray: #b5bdcc;
$gray-2: #bdcce9;
$light-blue: #053468;
$dark-blue: #142952;
$light-gray: #878fa4;
$medium-gray: #bbb;
$dark-gray: #aaa;
$black: #000;
$danger-color: #d31333;
$warning-color: yellow;
$success-color: green;
$red-1: #b31f3a;
$red-2: #b6354e;
$gray-3: #e1e4eb;
$gray-4: #f4f7fa;
$gray-5: #c0ccd9;
$gray-6: #a1aab3;
$blue-1: #46587e;

$main-fz: 16px;
$main-lh: 1.6;
$large-width: 1170px;
$desktop-width: 1250px;
$main-ff: 'Montserrat', "Arial", "Helvetica", sans-serif;
$second-ff: 'Oswald-Semibold', sans-serif;
$third-ff: 'Montserrat', sans-serif;
$fourth-ff: "Montserrat-Medium", sans-serif;

$main-text-color: $primary-color;
$headings-lh: 1.2;
$main-margin: $main-fz * $main-lh;

// grid settings
$gl-colCount: 24;
$gl-gridName: grid;
$gl-colName: col;
$gl-attributeName: class;
$gl-gutter: 30px; // Total left + right
$gl-gutter-vertical: 0;
$gl-mq-width: 'min-width';
$gl-mq-list: (
        xs: 320px,
        sm: 768px,
        md: 1200px,
        lg: 1600px
);

// media settings
$breakpoints: (small:320px, tablet: 768px, large: 1024px, xlarge: 1200px, desktop: 1440px, fullhd: 1920px);

body {
    font: #{$main-fz}/#{$main-lh} $main-ff;
    color: $main-text-color;
    background: $white;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin-bottom: 20px;
    font-weight: 600;
    line-height: 1.15;
    font-family: $fourth-ff;
}

h1, .h1 {
    font-size: 40px;
    letter-spacing: -1px;
    @include media('>=large') {
        font-size: 46px;
        letter-spacing: -1.34px;
    }
}

h2, .h2 {
    font-size: 32px;
    @include media('>=large') {
        font-size: 34px;
    }
}

h3, .h3 {
    font-size: 24px;
    @include media('>=large') {
        font-size: 24px;
    }
}

h4, .h4 {
    font-size: 18px;
    font-family: $third-ff;
    @include media('>=large') {
        font-size: 18px;
    }
}

h5, .h5 {
    font-size: 16px;
    font-family: $third-ff;
}

h6, .h6 {
    font-size: 14px;
    font-family: $third-ff;
}

p {
    margin-bottom: $main-margin;
    font-weight: 500;
}

a {
    text-decoration: none;
    color: $primary-color;
    outline: none;
}

ul {
    margin-bottom: $main-margin;
    font-family: $third-ff;
    li {
        position: relative;
        line-height: 35px;
        padding-left: 35px;
        &:before {
            content: "";
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            left: 0;
            top: 8px;
            background-image: url(#{$images-path}/icons/check_list.svg);
            @include media(">=large") {
                top: 4px;
            }
        }
    }
}

ol {
    margin-bottom: $main-margin;
    counter-reset: counterName;
    font-family: $third-ff;
    li {
        line-height: 35px;
        &:before {
            counter-increment: counterName;
            content: counters(counterName, "-") ". ";
        }

    }
    ul,
    ol {
        margin: 0;
        padding-left: 20px;
    }
}

// forms

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
textarea {
    display: inline-block;
    margin: 0 0 10px;
    border: none;
    border-bottom: 1px solid rgba($light-blue, .25);
    color: $primary-color;
    padding: 10px 0 5px;
    line-height: 1;
    font-size: 14px;
    font-weight: 500;
    vertical-align: top;
    background: transparent;
    outline: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    font-family: Arial, sans-serif;
    box-shadow: none;
    @include placeholder {
        color: $primary-color;
    }
}

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

textarea {
    height: 100px;
    border: 1px solid rgba($light-blue, .25);
    resize: none;
    padding: 10px;
}

select {
    display: inline-block;
    margin: 0 0 $main-margin;
    border: 1px solid $primary-color;
    padding: 5px 20px;
    line-height: 30px;
    font-size: 16px;
    height: 42px;
    vertical-align: middle;
    background: $white;
    outline: none;
    font-family: $third-ff;
    &:focus {
        border-color: $primary-color;
    }
}

.button,
input[type="submit"] {
    display: inline-block;
    margin: 0 0 20px;
    padding: 5px 17px;
    line-height: 38px;
    font-size: 16px;
    min-width: 137px;
    color: $white;
    font-family: $second-ff;
    font-weight: 500;
    vertical-align: top;
    text-decoration: none;
    white-space: nowrap;
    background: $primary-color;
    text-transform: uppercase;
    letter-spacing: .32px;
    cursor: pointer;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border: none;
    position: relative;
    overflow: hidden;
    text-align: center;
    span {
        z-index: 1;
        position: relative;
    }
    &:before {
        content: '';
        transition: transform .4s cubic-bezier(.37, .01, 0, .98);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #142952;
        transform: translateX(100%);
    }
    .no-touch & {
        &:hover {
            color: $white;
            &:before {
                transform: translateX(0);
            }
        }
    }
    &.button-secondary {
        background: $secondary-color;
        &:before {
            background-color: #9c0f27;
        }
    }
}

input[type="checkbox"] {
    display: inline-block;
    margin: 0 0 $main-margin;
    vertical-align: middle;
}

input[type="radio"] {
    display: inline-block;
    margin: 0 0 $main-margin;
    vertical-align: middle;
}

label, .title-label {
    display: inline-block;
    margin: 0 0 2px;
    vertical-align: middle;
    font-size: 16px;
    line-height: 26px;
    @include media('>=large') {
        font-size: 18px;
    }
    &.bottom-margin {
        margin-bottom: 25px;
    }
}

blockquote {
    margin: 0 0 $main-margin;
    p {
        margin: 0;
        &:before,
        &:after {
            font-size: 20px;
            line-height: 20px;
        }
        &:before {
            content: "\201C";
        }
        &:after {
            content: "\201D";
        }
    }
    q {
        quotes: none;
        &:before,
        &:after {
            quotes: none;
        }
    }
    cite {
        display: block;
    }
}

table {
    margin: 0 0 $main-margin;
    th,
    td {
        border: 1px solid $medium-gray;
        padding: 5px;
    }
}

[class^="col"] {
    > * {
        background: rgba(147, 128, 108, 0.2);
    }

}

