.quote-accordion {
    .slick-list {
        padding: 15px 0 15px 15px;
    }
    .slick-slide {
        margin-right: 8px;
        @include media('>=tablet') {
            margin-right: 0;
        }
    }
    .accordion-holder {
        padding: 0;
        margin: 0 -15px;
        visibility: hidden;
        display: block;
        justify-content: flex-start;
        @include media('>=tablet') {
            display: flex;
            margin: 0;
            visibility: visible;
        }
    }
    .item {
        background-color: $white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        cursor: pointer;
        box-shadow: 0 0 38px rgba($black, 0.05);
        padding: 33px 26px;
        position: relative;
        flex-shrink: 0;
        &:first-child:before {
            display: none;
        }
        @include media('>=tablet') {
            width: 91px;
            max-width: 353px;
            height: 171px;
        }
        &.active {
            @include media('>=tablet') {
                width: 100%;
                cursor: default;
                flex-shrink: 1;

                .form-group {
                    opacity: 1;

                    transition: opacity .3s ease;
                }
                .title {
                    text-align: left;
                    span {
                        opacity: 1;
                        transition: opacity .3s ease;
                    }
                    svg {
                        margin: 0 13px 0 0;
                        * {
                            fill: $secondary-color;
                            transition: fill .3s ease;
                        }
                    }
                }
                &:before {
                    opacity: 0;
                    transition: opacity .3s ease;
                }
                &:after {
                    opacity: 0;
                }
            }
        }
        &:after {
            @include media('>=tablet') {
                content: '';
                width: 12px;
                height: 12px;
                background: {
                    image: url(#{$images-path}/icons/plus.svg);
                    repeat: no-repeat;
                }
                position: absolute;
                bottom: 35px;
                left: calc(50% - 6px);
                @include transition(transform);
            }
        }
        &:before {
            display: none;
            @include media('>=tablet') {
                content: '';
                position: absolute;
                display: block;
                width: 32px;
                height: 100%;
                top: 0;
                left: 0;
                background: {
                    image: url(#{$images-path}/shadow-left.png);
                    repeat: no-repeat;
                    position: left center;
                }
            }
        }
        .no-touch &:not(.active):hover .title svg {
            transform: scale(1.12);
        }

    }
    .title {
        text-align: center;
        line-height: 1.4;
        margin-bottom: 30px;
        @include media('>=tablet') {
            margin-bottom: auto;
        }
        span {
            font-size: 20px;
            font-weight: 600;
            font-family: $second-ff;
            text-transform: uppercase;
            letter-spacing: 1px;
            @include media('>=tablet') {
                opacity: 0;
            }
        }
        svg {
            position: relative;
            max-width: 39px;
            max-height: 24px;
            display: inline-block;
            margin: 0 7px 0 0;
            @include transition(transform);
            @include media('>=tablet') {
                margin: auto;
            }
            * {
                fill: $secondary-color;
                @include media('>=tablet') {
                    fill: $primary-color;
                }
            }
        }
    }
    .form-group {
        margin-bottom: 0;
        text-align: center;
        @include media('>=tablet') {
            opacity: 0;
            text-align: left;
        }
        .custom-link {
            cursor: pointer;
            font-size: 15px;
        }
        .inline-block {
            margin-bottom: 0;
            > div {
                @include media('>=tablet') {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                }
            }
        }
        .floating-label {
            top: 9px;
            visibility: hidden;
            @include media('>=tablet') {
                visibility: visible;
            }
        }
        .active .floating-label {
            top: 9px;
        }
        .form-control {
            padding-top: 5px;
            padding-bottom: 5px;
            vertical-align: baseline;
            margin-bottom: 35px;
            width: 100%;
            @include media('>=tablet') {
                margin-bottom: 0;
                width: auto;
                margin-right: auto;
            }
        }
        @include media('>=tablet') {
            ::-webkit-input-placeholder {
                color: transparent;
            }
            ::-moz-placeholder {
                color: transparent;
            }
            :-ms-input-placeholder {
                color: transparent;
            }
            :-moz-placeholder {
                color: transparent;
            }
        }
    }
}

$elements: 6;

@for $i from $elements to 1 {
    .quote-accordion .item:nth-child(#{$i - 1}) {
        z-index: #{$elements - $i};
    }
}