.clients-block{
  position: relative;
  padding:60px 0;
  @include media(">=large") {
    padding:116px 0 96px;

  }

  &.theme-blue{
    background: $primary-color;
  }
  .title{
    color: #ffffff;
    margin:0 0 40px;
    font-size: 40px;
    @include media(">=large") {
      font-size: 54px;
      line-height: 60px;

    }
  }
}
.clients-list-wrap {
  h4, .h4 {
    font-family: $second-ff;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 20px;
    margin-bottom: 28px;
    text-align: center;
    @include media('>=tablet') {
      text-align: left;
    }
  }
  .clients-list{
    position: relative;
     @include media(">=tablet") {

       display: flex;
       margin: 0 -15px;
       flex-wrap: wrap;
     }
    @include media(">=large") {
    }
    .item {
      width: 100%;
      margin:0 0 30px;
       @include media(">=tablet") {
         width: calc(50% - 30px);
         margin:0 15px 30px;
       }
      @include media(">=large") {
        width: calc(25% - 30px);
        margin: 0 15px 30px;
      }
    }
    .pic {
      @include bg(center, center, cover);
      height: 226px;
    }
    .descr {
      box-shadow: 0 0 20px rgba(5, 51, 103, 0.06);
      background-color: #ffffff;
      padding: 30px;
      color: $primary-color;
      font-family: $second-ff;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-transform: uppercase;
      letter-spacing: 1.47px;
      text-align: center;
      position: relative;
      @include media('>=large') {
        padding: 52px 47px 42px;
      }
      &:before {
        content: '';
        background:  {
          image: url(#{$images-path}/icons/blockquote.svg);
          repeat: no-repeat;
        };
        width: 33px;
        height: 35px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -18px;
      }
    }
  }
}