.blog-single-wrap{
    .blog-single-head + .info-block.theme-blue{
        padding-bottom: 50px;
        &:before{
            content: none;
        }
    }
}
.blog-single-head{
    z-index: 10;
    position: relative;
    margin:0 0 150px;
    @include media(">=tablet") {
        margin: -9px 0 0;
        min-height:403px;
        padding-bottom: 340px;
        &:before{
            position: absolute;
            content: "";
            display: block;
            right: -100vw;
            left: -100vw;
            z-index: -1;
            background-color: #fff;
        }
        h1{
            margin-bottom: 30px;
        }
    }
    @include media(">=large") {
        padding-bottom: 270px;
    }
}


.return-row{
    margin:0 0 88px;
}

.post-details-wrap{
    position: relative;

    margin: 0 0 30px;
     @include media(">tablet") {
         display: flex;
         align-items: center;
     }
    .socials-colors{
        display: flex;
        align-items: center;
        .links {
            display: flex;
        }
        .links a{
            margin-left:18px;
            &:first-child{
                margin-left: 0;
                @include media(">tablet") {
                    margin-left:18px;
                }
            }
        }
    }
    .socials{
        margin: 0;
        li{
            padding-left:18px;
            &:first-child{
                padding-left: 0;
                 @include media(">tablet") {
                     padding-left:18px;
                 }
            }

        }
    }
    .author{
        white-space: nowrap;
        width: auto !important;
    }

}
.post-main-img{
    margin:0 0 70px;
    .info-block.theme-blue &{
        margin-top: -100px;
        @include media(">=tablet") {
            margin-top: -347px;

        }
    }
    img{
        display: block;
        max-width:100%;
        height: auto;
    }
}

.social-container {
    .fa-linkedin {
        path {
            fill: #007abc;
        }
    }
    .fa-instagram {
        background-image: linear-gradient(to top, #ff6044 0%, #ff6044 23%, #605bc9 77%, #605bc9 100%);
        
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 14px;
            height: 14px;
        }
        path {
            fill: $white;
        }
    }
}
