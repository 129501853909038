.contact-wrap {
    position: relative;
    padding-bottom: 80px;
    h1 {
        line-height: 54px;
        margin: -4px 0 28px;
    }
    @include media(">=large") {
        display: flex;
    }
    .summary {
        margin: 0 0 44px;
        font-size: 26px;
        line-height: 36px;
        @include media(">tablet") {
            padding-right: 38px;

        }
    }
}

.contact-col {
    position: relative;
    margin: 0 0 30px;
    @include media(">=large") {
        margin: 0;
        &:first-child {
            padding-right: 85px;
            width: 54%;
        }
        &:nth-child(2) {
            width: 46%;
        }
    }
    .contact-img {
        position: relative;
        height: 300px;
        margin: 0 0 50px;
        @include bg(center, center, cover);
        @include media(">large") {
            height: 550px;
            margin: 0 0 77px;

        }
    }
    .details-row {
        margin: 0 0 18px;
    }
}

.contact-details-wrap {
    @include media(">=tablet") {
        display: flex;
    }
    .icon {
        svg {
            width: 100%;
            height: 100%;
        }
    }
    .address-col {
        @include media(">=tablet") {
            margin-right: 52px;
        }
        .icon {
            width: 15px;
            height: 18px;
        }
    }
    .phones-col {
        .icon {
            width: 19px;
            height: 19px;
            background-color: $secondary-color;
            border-radius: 50%;
            svg {
                position: absolute;
                top: 4px;
                left: 5px;
                width: 10px;
                height: 10px;
                fill: $white;
            }
        }
    }
    .title {
        margin: 0 0 18px;
        padding-left: 33px;
        font-size: 18px;
        line-height: 24px;
    }
    .data,
    .phone {
        line-height: 28px;
        font-family: $second-ff;
        font-weight: 600;
        text-transform: uppercase;
    }
    .data {
        font-size: 16px;
    }
    .phone {
        display: inline-block;
        font-size: 20px;
        line-height: 24px;

    }
    .details-row {
        position: relative;
        padding: 0 0 0 33px;
    }
    .icon {
        position: absolute;
        left: 0;
        top: 4px;
    }
    .subtitle {
        margin: 0 0 8px;
        padding-left: 33px;
        font-size: 14px;
        line-height: 20px;
    }
}

.contact-form {
    position: relative;
    @include media(">=tablet") {
        padding-right: 34px;
    }
    .screen-reader-response {
        margin-bottom: 50px;
    }
    .field-wrap {
        //margin: 0 0 45px;
        &.select-mod {
            margin-top: -8px;
            @include media(">=large") {
                margin-top: -16px;
            }
        }
        input {
            width: 100%;
            //margin: 0;
        }
        .jcf-select {
            display: block;
        }

    }
    label {
        display: block;
        margin: 0 0 6px;
        font-size: 18px;
        line-height: 20px;
    }
    .note-row {
        margin: -21px 0 23px;
        @include media(">=large") {
            display: flex;
            justify-content: space-between;
        }
    }
    .note {
        font-size: 12px;
        line-height: 28px;
        color: $light-gray;
    }
}

.wpcf7-form.sent {
    //.jcf-scrollable-wrapper, .jcf-list-content {
    //    width: auto !important;
    //    height: auto !important;
    //}
    //.field-wrap .active .floating-label {
    //    top: 12px;
    //    font-size: 14px;
    //}
}