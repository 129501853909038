.side-socials-block {
    margin: 0 auto 53px;
    max-width: 148px;
    .socials {
        width: auto;
        margin: 0;
    }
    ul li {
        padding-left: 18px;
        &:first-child{
            padding-left: 0;
        }
        &:before {
            content: none;
        }
    }

}

